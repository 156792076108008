<ion-content>
  <div class="container">
    <div class="text-center py-2">
      <ion-icon name="checkmark-circle-outline" style="font-size: 70px;" class="text-green"></ion-icon>
      <h3>{{ 'MERCI'|translate }}</h3>
      <p>
        {{ 'onboarding-finished.MESSAGE_1' | translate }} <br />{{ 'onboarding-finished.MESSAGE_2' | translate }}
      </p>
    </div>
    <ul class="mt-2">
      <li *ngFor="let contact of selectedContacts">{{contact.firstname}} {{contact.lastname}}</li>
      <li *ngIf="numberSelectedContacts == 1">{{ 'onboarding-finished.ET' | translate }} {{ numberSelectedContacts }} {{ 'onboarding-finished.AUTRE' | translate }}.</li>
      <li *ngIf="numberSelectedContacts > 1">{{ 'onboarding-finished.ET' | translate }} {{ numberSelectedContacts }} {{ 'onboarding-finished.AUTRES' | translate }}.</li>
    </ul>
    <p class="mt-2 text-center">
      {{ 'onboarding-finished.NOTIFICATION' | translate }}
    </p>
    <p class="fw-bold mt-2 text-center">
      {{ 'onboarding-finished.FEEDBACK_RECURRENT' | translate }}
    </p>
    <div class="d-flex gap-2 mt-2">
      <button class="btn btn-green btn-rounded btn-outlined btn-block" (click)="close()">{{ 'NON'|translate }}</button>
      <button class="btn btn-green btn-rounded btn-block" (click)="sendRecurrentFeedback()">{{ 'OUI'|translate }}</button>
    </div>
  </div>
</ion-content>
