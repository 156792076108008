<div class="collapse-container over-ellipsis mb-2" [ngClass]="{opened: opened, shadowSize}" [class.card]="asCard">
  <div class="clickable d-flex justify-content-between" [ngClass]="paddingSize">
    <h4 [class]="titleClass">{{ title }}</h4>
    <ion-icon name="chevron-forward-outline" class="text-green fs-3" (click)="toggleContent()"></ion-icon>
  </div>
  <div class="content-container">
    <div class="content" [@collapse]="opened ? 'open' : 'closed'">
      <ng-content></ng-content>
    </div>
  </div>
</div>
