import {Component} from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {FeedbackService} from '../../../services/feedback.service';
import {Contact} from '../../../models/contact';
import {ContactService} from '../../../services/contact.service';
import {TranslateService} from '../../../services/translate.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {LoadingHelper} from "../../../utils/loading.helper";

@Component({
    selector: 'app-feedback-pick-evaluator',
    templateUrl: './feedback-pick-evaluator.page.html',
    styleUrls: ['./feedback-pick-evaluator.page.scss'],
})
export class FeedbackPickEvaluatorPage {

    selectedContacts: Contact[] = [];
    disabled = false;
    skillIds: number[];
    loaded = false;
    skillId: number;

    constructor(
        private readonly feedbackService: FeedbackService,
        private readonly navCtrl: NavController,
        private readonly modalCtrl: ModalController,
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
        private readonly contactService: ContactService,
    ) { }

    ionViewWillEnter() {
        const skillIds = this.feedbackService.requestFeedback.skillIds;
        this.skillIds = skillIds ? (Array.isArray(skillIds) ? skillIds : [skillIds]) : [];
        this.skillId = skillIds.length >= 2 ? null : this.skillIds[0];
        this.loaded = this.skillIds.length > 0;
        // Make sure there is no static loader
        LoadingHelper.close();
    }

    navigateBack() {
        this.navCtrl.back();
    }

    async submit() {
        this.disabled = true;
        const skillIds = this.skillIds;
        // Check selected contacts
        for (const skillId of skillIds) {
            const valid = await this.contactService.checkMinRequiredContacts(this.selectedContacts, skillId);
            if (!valid) {
                this.disabled = false;
                return;
            }
        }

        await LoadingHelper.open();
        // Send the feedbacks requests
        this.feedbackService.requestFeedback.contacts = this.selectedContacts;
        for (const skillId of skillIds) {
            const success = await this.sendFeedback(skillId, this.feedbackService.requestFeedback.eventTitle);
            if (!success) {
                return;
            }
            // Remove skill from list
            this.skillIds = this.skillIds.filter(s => s !== skillId);
        }

        // Navigate to confirmation page
        await this.navCtrl.navigateForward('/feedbacks/request/confirmation');
        this.disabled = false;
    }

    private async sendFeedback(skillId: number, eventTitle: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            // Send the feedbacks requests
            this.feedbackService.postFeedbackRequest(
                this.feedbackService.requestFeedback.contacts,
                skillId,
                eventTitle
            ).subscribe({
                next: () => {
                    resolve(true);
                },
                error: async () => {
                    this.disabled = false;
                    const alert = await this.alertCtrl.create({
                        header: this.translateService.get(_('ERREUR_PRODUITE')),
                        buttons: [this.translateService.get(_('OK'))],
                    });
                    await alert.present();
                    resolve(false);
                }
            });
        });
    }
}
