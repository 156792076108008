<!-- Feedback ouvert avec au moins un feedback manquant -->
<p class="fs-5" *ngIf="isOpenFeedback && hasMissingFeedbacks && missingFeedbackList.length === 0" [innerHTML]="'feedback.MISSING_FB'|translate: {count: totalMissingFeedbacks}"></p>
<!-- Feedback fermé ou feedback ouvert avec liste de feedbacks manquants -->
<p class="fs-5" *ngIf="(!isOpenFeedback || (isOpenFeedback && missingFeedbackList.length === 1)) && hasMissingFeedbacks" [innerHTML]="'feedback.MISSING_FB_LIST'|translate: {count: totalMissingFeedbacks}"></p>
<!-- Feedback ouvert avec liste de feedbacks manquants -->
<p class="fs-5" *ngIf="isOpenFeedback && missingFeedbackList.length > 1 && hasMissingFeedbacks" [innerHTML]="'feedback.MISSING_AT_LEAST_FB'|translate: {count: totalMissingFeedbacks}"></p>
<!-- Liste des feedbacks manquants -->
<p *ngFor="let item of missingFeedbackList" [innerHTML]="item"></p>
<!-- Aucun feedback manquant et indice fort -->
<p class="fs-5 text-green" *ngIf="!hasMissingFeedbacks && qualityIndex === 'A'">{{ 'feedback.BRAVO_INDICE_FORT'|translate }}</p>
<!-- Aucun feedback manquant et non fort -->
<p class="fs-5" *ngIf="!hasMissingFeedbacks && qualityIndex !== 'A'">{{ 'feedback.BRAVO_AUCUN_FB_MANQUANT'|translate }}</p>
