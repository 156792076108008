import {Component, Input, OnInit} from '@angular/core';
import {Skill, SkillCategory, SkillFamilyGroup, SkillGroup} from '../../models/skill';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-skill-picker',
  templateUrl: './skill-picker.component.html',
  styleUrls: ['./skill-picker.component.scss'],
  providers: [{useExisting: SkillPickerComponent, provide: NG_VALUE_ACCESSOR, multi: true}]
})
export class SkillPickerComponent  implements OnInit, ControlValueAccessor {

  familyGroups: SkillFamilyGroup[];
  @Input() set groups(groups: SkillGroup[]) {
    if (!groups) {
      return;
    }
    const familyGroups = Skill.skillGroupByFamily(groups);
    for (const familyGroup of familyGroups) {
      for (const cat of familyGroup.categories) {
        this.showAll[familyGroup.family.id + '-' + cat.category.id] = cat.skills.length > 5 ? false : null;
      }
    }
    this.familyGroups = familyGroups;
  }
  onChange: any;
  onTouched: any;
  selectedSkills: Skill[] = [];
  // true if all skills are shown, false if only 5 are shown, null if not enough skills to show the toggle
  showAll: { [key: string]: boolean } = {};

  constructor() { }

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.selectedSkills = obj || [];
  }

  toggleSkill(skill: Skill) {
    if (this.selected(skill)) {
      this.selectedSkills = this.selectedSkills.filter(s => s.id !== skill.id);
    } else {
      this.selectedSkills.push(skill);
    }
    this.onChange?.(this.selectedSkills);
    this.onTouched?.();
  }

  selected(skill: Skill) {
    return this.selectedSkills?.find(s => s.id === skill.id);
  }

  toggleShowMore(cat: {  skills: Skill[]; category: SkillCategory }, family: SkillFamilyGroup): void {
    this.showAll[family.family.id + '-' + cat.category.id] = !this.showAll[family.family.id + '-' + cat.category.id];
  }

  isShowingMore(cat: {  skills: Skill[]; category: SkillCategory }, family: SkillFamilyGroup): boolean {
    return this.showAll[family.family.id + '-' + cat.category.id] === true;
  }

  shouldDisplayToggle(cat: {  skills: Skill[]; category: SkillCategory }, family: SkillFamilyGroup): boolean {
    return this.showAll[family.family.id + '-' + cat.category.id] !== null;
  }

  getSkills(cat: { skills: Skill[]; category: SkillCategory }, family: SkillFamilyGroup) {
    // If more than 5 skills, show only 5
    return this.showAll[family.family.id + '-' + cat.category.id] !== false ? cat.skills : cat.skills.slice(0, 5);
  }
}
