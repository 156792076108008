import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: ButtonGroupComponent, multi: true}
  ]
})
export class ButtonGroupComponent  implements ControlValueAccessor {

  @Input() multiple = false;
  @Input() items: {label: string, value: any}[];
  @Input() disabled = false;
  selectedItems: string[] = [];
  private selecting = false;

  onChange = (items: string|string[]) => {};
  onTouched = () => {};

  constructor() { }

  toggleSelection(item: string) {
    if (this.selecting || this.disabled) {
        return;
    }
    this.selecting = true;

    const isSelected = this.selectedItems.includes(item);
    if (this.multiple) {
      if (isSelected) {
        this.selectedItems = this.selectedItems.filter(i => i !== item);
      } else {
        this.selectedItems.push(item);
      }
    } else {
      this.selectedItems = [item];
    }

    this.onChange?.(this.multiple ? this.selectedItems : this.selectedItems[0]);
    this.onTouched?.();
    this.selecting = false;
  }

  registerOnChange(fn: (items: string|string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(items: string|string[]): void {
    this.selectedItems = Array.isArray(items) ? items : [items];
    this.onChange?.(this.multiple ? this.selectedItems : this.selectedItems[0]);
  }

}
