<ion-content>
	<ng-container *ngIf="feedbacks else loading">
		<app-fb-answer
				[feedbacks]="feedbacks"
				*ngIf="feedbacks"
                [hasRecommendation]="false"
				type="onboarding"
				(feedbacksAnswered)="onFeedbacksAnswered()"
		></app-fb-answer>
	</ng-container>
	<ng-template #loading>
		<!-- Loader -->
		<div class="py-4 text-center">
			<ion-spinner name="crescent"></ion-spinner>
		</div>
	</ng-template>
</ion-content>
