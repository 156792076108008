import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {marker as _} from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: StarRatingComponent, multi: true}
  ]
})
export class StarRatingComponent implements ControlValueAccessor {

  @Input() disabled = false;
  @Input() rating: number;
  @Input() noOpinionAvailable = true;

  onChange = (rating: number) => {};
  onTouched = () => {};

  constructor() { }

  rate(star: number) {
    if (this.disabled) {
      return;
    }
    this.rating = star;
    this.onChange?.(star);
  }

  getBackgroundColor(i: number) {
    if (!this.rating || i > this.rating) {
      return '#999';
    }
    switch (i) {
      case 5:
        return '#39AA91';
      case 4:
        // 10% lighter
        return '#4DBEA4';
      case 3:
        // 20% lighter
        return '#5FC7B7';
      case 2:
        // 30% lighter
        return '#71D0CA';
      case 1:
        // 40% lighter
        return '#83D9DD';
    }
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(rating: number): void {
    this.rating = rating;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isDefined(): boolean {
    return this.rating !== undefined && this.rating !== null;
  }

  getLabel(rating: number) {
    switch (rating) {
      case -1:
        return _('rating.LABEL_0');
      case 1:
        return _('rating.LABEL_1');
      case 2:
        return _('rating.LABEL_2');
      case 3:
        return _('rating.LABEL_3');
      case 4:
        return _('rating.LABEL_4');
      case 5:
        return _('rating.LABEL_5');
    }
  }
}
