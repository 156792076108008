import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService
{
    constructor(private readonly storageService: Storage) {
    }

    set(key: StorageKey, value: any): Promise<any>
    {
        return this.storageService.set(key, value);
    }

    get(key: StorageKey): Promise<any>
    {
        return this.storageService.get(key);
    }

    remove(key: StorageKey) {
        return this.storageService.remove(key);
    }
}

export type StorageKey = 'onboarding-selection'
    |'contacts'
    |'contacts_imported_at'
    |'session_data'
    |'settings'
    |'user_credentials'
    |'fb-dont-notify'
    |'fb_sent_count'
    ;
