import {AuthData} from '../models/auth-status';
import {distance} from 'fastest-levenshtein';

export class StringUtils {
    static readonly EMAIL_REGEX = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

    static userFullname(user: AuthData): string {
        const firstname = user?.data?.user?.firstname || '';
        const lastname = user?.data?.user?.lastname || '';

        return (firstname + ' ' + lastname).trim();
    }

    public static checkAnswerMatch(expected: string, actual: string): boolean {
        // Lowercase and trim
        expected = expected.toLowerCase().trim();
        actual = actual.toLowerCase().trim();

        // Convert all accents to normal letters
        expected = StringUtils.removeAccents(expected);
        actual = StringUtils.removeAccents(actual);

        const threshold = 0.75;
        const levDistance = distance(expected, actual);
        const match = distance(expected, actual) <= threshold;

        console.log(expected, actual, levDistance, threshold, match);

        return match;
    }

    public static removeAccents(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
