import { Component, OnInit } from '@angular/core';
import {OnboardingService} from "../../../services/onboarding.service";

@Component({
  selector: 'app-onboarding-challenges',
  templateUrl: './onboarding-challenges.component.html',
  styleUrls: ['./onboarding-challenges.component.scss'],
})
export class OnboardingChallengesComponent  implements OnInit {

  constructor(private readonly onboardingService: OnboardingService) { }

  ngOnInit() {}

  close() {
    this.onboardingService.onboardingFinished('challenge').subscribe();
    this.onboardingService.close();
  }

}
