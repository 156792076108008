import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import {LinkService} from '../../../services/link.service';
import {OnboardingService} from "../../../services/onboarding.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-onboarding-terms',
    templateUrl: './onboarding-terms.page.html',
    styleUrls: ['./onboarding-terms.page.scss'],
})
export class OnboardingTermsPage implements OnInit {

    public content: any;

    constructor(
        private readonly linkService: LinkService,
        private readonly onboardingService: OnboardingService,
        private readonly sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
    }

    ionViewWillEnter() {
        this.linkService.getCGU().subscribe(data => {
            this.content = this.sanitizer.bypassSecurityTrustHtml(data);
        });
    }

    next(){
        this.onboardingService.goTo('slides', true);
    }

}
