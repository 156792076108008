<div class="d-flex justify-content-evenly footer-container" *ngIf="displayedTab">
  <div *ngFor="let tab of displayedTab" class="w-100">
    <a [routerLink]="tab.path"
       routerLinkActive="active"
    >
      <span class="icon coss-{{ tab.icon }}"></span>
      <span>{{ tab.label|translate }}</span>
    </a>
  </div>
</div>
