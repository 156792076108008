<ion-content>
  <div class="container d-flex justify-content-start flex-column h-100">
    <div class="d-flex justify-content-end m-2">
      <div class="fs-2 text-green pt-1" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center p-relative h-100">
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="slides">
        <ion-icon name="chevron-back-outline" class="swiper-nav text-green fs-2 p-1" [class.enabled]="canSlideBack()" (click)="slideBack()"></ion-icon>
      </div>
      <swiper #slides [config]="swiperConfig" *ngIf="swiperConfig">
        <ng-template swiperSlide>
          <div class="text-center">
            <img class="mb-2" src="assets/images/onboarding/onboarding-answer-1.svg" [style.width]="'150px'">
            <h3>{{ 'onboarding-answer.ANONYMAT'|translate }}</h3>
            <p class="text-muted fs-5 fw-600 mt-1">
                {{ 'onboarding-answer.REPONSES_ANONYMES'|translate }}
            </p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="text-center">
            <img class="mb-2" src="assets/images/onboarding/onboarding-answer-3.png" [style.width]="'150px'">
            <h3>{{ 'onboarding-answer.OBJECTIVITE'|translate }}</h3>
            <p class="text-muted fs-5 fw-600 mt-1">
              {{ 'onboarding-answer.OBJECTIVITE_DETAIL'|translate }}
            </p>
          </div>
        </ng-template>
      </swiper>
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="slides">
        <ion-icon name="chevron-forward-outline" class="swiper-nav text-green fs-2 p-1" [class.enabled]="canSlideForward()" (click)="slideForward()"></ion-icon>
      </div>
    </div>
    <div class="p-relative py-2">
      <div class="swiper-pagination green" #slidePagination>
        <div class="swiper-pagination-bullet" *ngFor="let slide of [1,2,3,4]"></div>
      </div>
    </div>
  </div>
</ion-content>
