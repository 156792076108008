import { Component, OnInit } from '@angular/core';
import {OnboardingService} from '../../../services/onboarding.service';
import {AuthService} from '../../../services/auth.service';
import {AuthData} from '../../../models/auth-status';
import {SkillPath} from '../../../models/skill';
import {LoadingController} from '@ionic/angular';
import {SkillService} from '../../../services/skill.service';

@Component({
    selector: 'app-onboarding-pick-course',
    templateUrl: './onboarding-pick-course.component.html',
    styleUrls: ['./onboarding-pick-course.component.scss'],
})
export class OnboardingPickCourseComponent  implements OnInit {

    authData: AuthData;
    skillPaths: SkillPath[];
    selectedPath: number;

    constructor(
        private readonly onboardingService: OnboardingService,
        private readonly authService: AuthService,
        private readonly loadingCtrl: LoadingController,
        private readonly skillService: SkillService
        ) { }

    ngOnInit() {
        // Load skill paths
        this.authService.authStatus.subscribe({
            next: status => {
                if (status.authDone && status.authData && !this.authData) {
                    this.skillPaths = status.authData.data.skillsPaths;
                }
            }
        });
    }

    close() {
        this.onboardingService.close();
    }

    async submit() {
        // Start loader
        const loader = await this.loadingCtrl.create();
        loader.present();
        this.skillService.pickSkillPath(this.selectedPath).subscribe({
            next: () => {
                loader.dismiss();
                this.onboardingService.skillOrSkip();
            }
        });
    }

    selectPath(path: SkillPath) {
        if (this.selectedPath === path.id) {
            this.selectedPath = null;
            return;
        }
        this.selectedPath = path.id;
    }
}
