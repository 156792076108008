<ion-content>
	<div class="container pb-5">
		<div class="d-flex justify-content-between">
			<h1>{{ 'onboarding-skills.CHOISIR_COMP' | translate }}</h1>
			<div class="fs-2 text-green p-1" (click)="close()">
				<ion-icon name="close-outline"></ion-icon>
			</div>
		</div>
		<p class="py-2">
			{{ 'onboarding-skills.MESSAGE' | translate }}<br/>
			<strong>5 {{ 'onboarding-skills.MAXIMUM' | translate }}</strong>
		</p>
		<app-skill-picker [groups]="skillGroups" [(ngModel)]="selectedSkills"></app-skill-picker>
	</div>
	<button type="button" (click)="nextStep()" slot="fixed" class="btn btn-green btn-rounded btn-fixed-bottom btn-block">
		{{ 'onboarding-skills.SUIVANT' | translate }}
	</button>
</ion-content>
