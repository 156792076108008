import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-tooltip-modal',
    templateUrl: './tooltip-modal.component.html',
    styleUrls: ['./tooltip-modal.component.scss'],
})
export class TooltipModalComponent implements OnInit {

    @Input() text: string;
    @Input() icon: string;
    @Input() title: string;

    constructor(
        private readonly modalCtrl: ModalController,
    ) { }

    ngOnInit() {}

    close() {
        this.modalCtrl.dismiss();
    }
}
