import {NgModule} from '@angular/core';
import { SkillPicturePipe } from './skill-picture.pipe';
import {QuestionPipe} from './question.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { GradePipe } from './grade.pipe';

@NgModule({
    declarations: [
        SkillPicturePipe,
        QuestionPipe,
        FormatDatePipe,
        GradePipe,
    ],
    imports: [],
    exports: [
        SkillPicturePipe,
        QuestionPipe,
        FormatDatePipe,
        GradePipe,
    ],
})

export class PipesModule {}
