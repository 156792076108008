import {Component} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {AuthData} from '../../../models/auth-status';
import {OnboardingService} from '../../../services/onboarding.service';
import {Contact} from '../../../models/contact';
import {LoadingController} from '@ionic/angular';
import {FeedbackService} from '../../../services/feedback.service';
import {StorageService} from '../../../services/storage.service';
import {ContactService} from '../../../services/contact.service';
import {Skill} from '../../../models/skill';
import {OnboardingSelectionStored} from '../../../models/onboarding';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'app-onboarding-contacts',
    templateUrl: './onboarding-contacts.page.html',
    styleUrls: ['./onboarding-contacts.page.scss'],
})
export class OnboardingContactsPage {

    public authData: AuthData;
    public selectedContacts: Contact[] = [];

    constructor(
        private readonly authService: AuthService,
        private readonly onboardingService: OnboardingService,
        private readonly loadingCtrl: LoadingController,
        private readonly feedbackService: FeedbackService,
        private readonly storageService: StorageService,
        private readonly contactService: ContactService,
        private readonly userService: UserService,
    ) {
    }

    ionViewWillEnter() {
        this.authService.authStatus.subscribe({
            next: status => {
                if (status?.authDone && !this.authData) {
                    this.authData = status.authData;
                }
            }
        });
    }

    close() {
        this.authData.data.user.is_onboarding_finished = true;
        this.onboardingService.onboardingFinished().subscribe();
        this.onboardingService.close();
    }

    async continue() {
        const res: OnboardingSelectionStored = await this.storageService.get('onboarding-selection');
        const skills = res?.skills;

        // Check contact selection
        const valid = await this.contactService.checkMinRequiredContacts(this.selectedContacts, null, true);
        if (!valid) {
            return;
        }

        // Ask for feedbacks
        const loading = await this.loadingCtrl.create();
        await loading.present();

        // Save users contacts
        res.contacts = this.selectedContacts;
        await this.storageService.set('onboarding-selection', res);

        await new Promise<void>(resolve => {
            this.feedbackService.postOnboardingFeedbackRequest(this.selectedContacts, skills.map(s => s.id)).subscribe({
                next: () => {
                    resolve();
                },
                error: () => {
                    resolve();
                }
            });
        });

        await loading.dismiss();

        // Refresh user data
        this.userService.updateUserInfo().subscribe();

        // Finish onboarding
        this.onboardingService.goTo('finished', true);
    }
}
