<div class="full-height padded">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="fs-2 text-green clickable" *ngIf="canGoBack" (click)="navigateBack()"><span class="icon coss-arrow-left"></span></div>
        <h1 [innerHTML]="title ? title : ('evaluator.CHOISIR_REPONDANTS'|translate)"></h1>
      </div>
    </div>

    <div class="tab-selector mt-2" *ngIf="!(mode === 'update' && type === 'school')">
      <div class="tab-selector-inner">
        <button type="button" class="btn btn-rounded tab-selector-item"
                (click)="selectTab(i)"
                [class.active]="i === activeTab"
                *ngFor="let tab of tabs; let i = index"
        >
          {{ tab.name|translate }}
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start align-items-center gap-2 pt-2">
      <form (submit)="filterContacts()" class="input-group">
        <ion-icon name="search-outline" class="icon fs-3"></ion-icon>
        <input class="form-control" name="searchQuery" [placeholder]="'evaluator.RECHERCHER_LISTE_CONTACTS'|translate" [(ngModel)]="searchQuery" (ngModelChange)="filterContacts()">
      </form>
      <div class="text-green fs-3 clickable" *ngIf="!(mode === 'update' && type === 'school')">
        <span class="icon coss-list-add" (click)="createContact()"></span>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="container">
      <div class="pb-2" *ngIf="mode === 'selection' && showFbCriterias">
        <app-fb-criteria [stat]="stat" [skill]="skill"></app-fb-criteria>
      </div>
      <!--    <p class="fs-4 fw-500" [innerHTML]="'evaluator.CONDITIONS'|translate"></p>-->
      <h3 class="mb-1">{{ 'evaluator.MES_CONTACTS'|translate }}</h3>
      <p class="explain" *ngIf="mode === 'update' && type === 'pro'">
        {{ 'evaluator.MES_CONTACTS_PRO_EXPLAIN'|translate }}
      </p>
      <p class="explain" *ngIf="mode === 'update' && type === 'school'">
        {{ 'evaluator.MES_CONTACTS_SCHOOL_EXPLAIN'|translate }}
      </p>
      <p class="explain" *ngIf="mode === 'update' && type === 'external_pro'">
        {{ 'evaluator.MES_CONTACTS_EXTERNAL_PRO_EXPLAIN'|translate }}
      </p>
      <p class="explain" *ngIf="mode === 'update' && type === 'sport_asso'">
        {{ 'evaluator.MES_CONTACTS_SPORT_ASSO_EXPLAIN'|translate }}
      </p>
      <ng-container *ngIf="displayContacts else loadingContacts">
        <div class="py-4 " *ngIf="displayContacts.length === 0">
          <p class="text-center text-muted">{{ 'contacts.AUCUN_CONTACT'|translate }}</p>
        </div>
        <div class="card clickable px-2 mb-1" *ngFor="let contact of displayContacts" (click)="selectContact(contact)">
          <div class="d-flex justify-content-start align-items-center gap-1">
            <div>
                <ion-icon [name]="contact.icon" class="icon text-blue fs-2"></ion-icon>
            </div>
            <div>
              <span class="d-flex align-items-center text-blue-dark fs-4 text-break-word">{{ contact.name }}&nbsp;&nbsp;<ion-icon *ngIf="contact.status !== undefined" [name]="contact.status ? 'checkmark-circle' : 'help-circle'" [class]="contact.status ? 'text-green' : 'text-orange'"></ion-icon></span>
              <span class="d-flex text-blue fs-5 text-break-word">{{ contact.email }}</span>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <ng-container *ngIf="canSelect(contact) else lastContactText">
                <div class="d-flex justify-content-end align-items-end flex-column">
                  <ion-icon name="square-outline" class="icon text-black fs-2" *ngIf="!contactSelection[contact.email]"></ion-icon>
                  <ion-icon name="checkbox" class="icon text-green fs-2" *ngIf="contactSelection[contact.email]"></ion-icon>
                  <span class="fs-6 text-muted text-right"
                        *ngIf="contact.lastContact !== undefined"
                        [innerHTML]="'evaluator.DERNIER_CONTACT'|translate:{ days: contact.contactedDaysAgo }"
                  ></span>
                </div>
              </ng-container>
              <ng-template #lastContactText>
                <span class="fs-6 text-muted text-right" [innerHTML]="'DISPO_JOUR'|translate:{ days: contact.daysBeforeAvailable }"></span>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #loadingContacts>
        <div class="py-4 text-center text-black fs-4">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
