import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Settings, SettingsKey} from '../models/settings';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    settings = new BehaviorSubject<Settings>({
        darkMode: null,
        language: null,
    });
    settingsChanged = new EventEmitter<SettingsKey>();

    constructor(private readonly storageService: StorageService) { }

    public setSettings(key: SettingsKey, value: any) {
        const settings = this.settings.value;
        const newSettings = {...settings, [key]: value};
        this.storageService.set('settings', newSettings);
        this.settings.next(newSettings);
        this.settingsChanged.emit(key);
    }

    public async initSettings() {
        let settings = await this.storageService.get('settings');
        if (!settings) {
            settings = {
                darkMode: null,
                language: null,
            };
            this.storageService.set('settings', settings);
        }

        this.settings.next(settings);
    }

    getSettings(key: SettingsKey): any {
        return this.settings.value[key];
    }
}
