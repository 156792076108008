import {NgModule} from '@angular/core';
import {FooterToolbarComponent} from './components/footer-toolbar/footer-toolbar.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './components/card/card.component';
import {IonicModule} from '@ionic/angular';
import {SwiperModule} from 'swiper/angular';
import {ExploreChallengesComponent} from './components/explore-challenges/explore-challenges.component';
import {
    ChallengeQuestionSelectComponent
} from './components/challenge-question-select/challenge-question-select.component';
import {BadgeComponent} from './components/badge/badge.component';
import {CardCollapseComponent} from './components/card-collapse/card-collapse.component';
import {AddBadgeWalletComponent} from './modals/add-badge-wallet/add-badge-wallet.component';
import {BadgeShareComponent} from './modals/badge-share/badge-share.component';
import {ReliabilityIndexComponent} from './components/reliability-index/reliability-index.component';
import {StarRatingComponent} from './components/star-rating/star-rating.component';
import {ButtonGroupComponent} from './components/button-group/button-group.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {TooltipModalComponent} from './modals/tooltip-modal/tooltip-modal.component';
import {ProgressChartComponent} from './components/progress-chart/progress-chart.component';
import {EvaluationComponent} from './components/evaluation/evaluation.component';
import {AddContactPage} from './modals/add-contact/add-contact.page';
import {CoffeeRequestPage} from './modals/coffee-request/coffee-request.page';
import {EditProfilePage} from './modals/edit-profile/edit-profile.page';
import {RecurrentFeedbacksPage} from './modals/recurrent-feedbacks/recurrent-feedbacks.page';
import {ResetSkillsPage} from './modals/reset-skills/reset-skills.page';
import {ShareEventLinkPage} from './modals/share-event-link/share-event-link.page';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from './pipes/pipes.module';
import {MomentModule} from 'ngx-moment';
import {
    FbRequestRecurrentModalComponent
} from './modals/fb-request-recurrent-modal/fb-request-recurrent-modal.component';
import {FbRequestNotifyModalComponent} from './modals/fb-request-notify-modal/fb-request-notify-modal.component';
import {TermsPage} from './modals/terms/terms.page';
import {AddBadgeWalletExistsComponent} from './modals/add-badge-wallet-exists/add-badge-wallet-exists.component';
import {WalletModal} from './modals/create-wallet/wallet-modal.component';
import {FbRequestEventTitleComponent} from './modals/fb-request-event-title/fb-request-event-title.component';
import {FbCriteriaComponent} from './components/criterias/fb-criteria/fb-criteria.component';
import {GradeCriteriaComponent} from './components/criterias/grade-criteria/grade-criteria.component';
import {GridComponent} from './components/grid/grid.component';
import {AddContactTitleComponent} from './modals/add-contact-title/add-contact-title.component';
import {FbAnswerComponent} from './components/fb-answer/fb-answer.component';
import {BehaviorListComponent} from './components/behavior-list/behavior-list.component';
import {LeftMenuComponent} from './components/left-menu/left-menu.component';
import {FbEventComponent} from "./components/feedbacks/fb-event/fb-event.component";
import {SkillPickerComponent} from "./components/skill-picker/skill-picker.component";
import {PromotedSkillsComponent} from "./components/promoted-skills/promoted-skills.component";
import {ExplainSkillModalComponent} from "./modals/explain-skill-modal/explain-skill-modal.component";
import {NoContactModalComponent} from "./modals/no-contact-modal/no-contact-modal.component";
import {RecommendationListComponent} from "./components/recommendation-list/recommendation-list.component";

@NgModule({
    declarations: [
        FooterToolbarComponent,
        CardComponent,
        ExploreChallengesComponent,
        ChallengeQuestionSelectComponent,
        BadgeComponent,
        CardCollapseComponent,
        AddBadgeWalletComponent,
        BadgeShareComponent,
        ReliabilityIndexComponent,
        StarRatingComponent,
        ButtonGroupComponent,
        TooltipComponent,
        TooltipModalComponent,
        ProgressChartComponent,
        EvaluationComponent,
        AddContactPage,
        CoffeeRequestPage,
        EditProfilePage,
        RecurrentFeedbacksPage,
        ResetSkillsPage,
        ShareEventLinkPage,
        FbRequestRecurrentModalComponent,
        FbRequestNotifyModalComponent,
        FbRequestEventTitleComponent,
        TermsPage,
        AddBadgeWalletExistsComponent,
        WalletModal,
        FbCriteriaComponent,
        GradeCriteriaComponent,
        AddContactTitleComponent,
        FbAnswerComponent,
        GridComponent,
        BehaviorListComponent,
        LeftMenuComponent,
        FbEventComponent,
        SkillPickerComponent,
        PromotedSkillsComponent,
        ExplainSkillModalComponent,
        NoContactModalComponent,
        RecommendationListComponent,
    ],
    exports: [
        FooterToolbarComponent,
        CardComponent,
        SwiperModule,
        ExploreChallengesComponent,
        ChallengeQuestionSelectComponent,
        BadgeComponent,
        CardCollapseComponent,
        AddBadgeWalletComponent,
        BadgeShareComponent,
        ReliabilityIndexComponent,
        StarRatingComponent,
        ButtonGroupComponent,
        TooltipComponent,
        TooltipModalComponent,
        ProgressChartComponent,
        EvaluationComponent,
        AddContactPage,
        CoffeeRequestPage,
        EditProfilePage,
        RecurrentFeedbacksPage,
        ResetSkillsPage,
        ShareEventLinkPage,
        FbRequestRecurrentModalComponent,
        FbRequestNotifyModalComponent,
        FbRequestEventTitleComponent,
        TermsPage,
        AddBadgeWalletExistsComponent,
        WalletModal,
        FbCriteriaComponent,
        GradeCriteriaComponent,
        AddContactTitleComponent,
        FbAnswerComponent,
        GridComponent,
        BehaviorListComponent,
        LeftMenuComponent,
        FbEventComponent,
        SkillPickerComponent,
        PromotedSkillsComponent,
        ExplainSkillModalComponent,
        NoContactModalComponent,
        RecommendationListComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        IonicModule,
        SwiperModule,
        FormsModule,
        PipesModule,
        MomentModule,
        ReactiveFormsModule,
    ]
})
export class SharedModule {}
