import { Component, OnInit } from '@angular/core';
import {NavController, AlertController, LoadingController} from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import {SkillService} from '../../../services/skill.service';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '../../../services/translate.service';
import {OnboardingService} from '../../../services/onboarding.service';
import {AuthData} from '../../../models/auth-status';
import {StorageService} from '../../../services/storage.service';
import {marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {Skill, SkillGroup} from '../../../models/skill';
import {FeedbackService} from '../../../services/feedback.service';

@Component({
    selector: 'app-onboarding-skills',
    templateUrl: './onboarding-skills.page.html',
    styleUrls: ['./onboarding-skills.page.scss'],
})
export class OnboardingSkillsPage {

    public authData: AuthData;
    public skillGroups: SkillGroup[];
    selectedSkills: Skill[] = [];

    constructor(
        private readonly navCtrl: NavController,
        private readonly skillService: SkillService,
        private readonly authService: AuthService,
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
        private readonly onboardingService: OnboardingService,
        private readonly storageService: StorageService,
        private readonly loadingCtrl: LoadingController,
    ) {}

    ionViewWillEnter() {
        this.skillService.getSkillGroups().subscribe(groups => {
            // Only get skill groups with skill without closed feedbacks
            groups.forEach(g => {
                g.skills = g.skills.filter(s => s.hasOpenedFeedbackCriteria());
            });
            // Remove groups with no skills
            this.skillGroups = groups.filter(g => g.skills.length > 0);
        });
        this.authData = this.authService.authStatus.value.authData;
    }

    async nextStep() {
        // Between 1 and {maxSkills} skills must be selected
        const maxSkills =  this.authData.data.skillsLimit || 5;
        if (this.selectedSkills.length === 0) {
            const alert = await this.alertCtrl.create({
                header: this.translateService.get(_('onboarding-skills.TITLE_1')),
                subHeader: this.translateService.get(_('onboarding-skills.MESSAGE_1')),
                buttons: [this.translateService.get(_('onboarding-skills.BUTTON_1'))]
            });
            await alert.present();
            return;
        } else if (this.selectedSkills.length > maxSkills) {
            const subHeader = this.translateService.get('onboarding-skills.MESSAGE_2', {value: this.selectedSkills.length, max: maxSkills});
            const alert = await this.alertCtrl.create({
                header: this.translateService.get(_('onboarding-skills.TITLE_1')),
                subHeader,
                buttons: [this.translateService.get(_('onboarding-skills.BUTTON_1'))]
            });
            alert.present();
            return;
        }

        const loading = await this.loadingCtrl.create();
        loading.present();

        // Save selected skills
        this.skillService.postUserSkills(this.selectedSkills.map(s => ({id: s.id}))).subscribe(data => {
            // Save to database
            this.storageService.set('onboarding-selection', {
                skills: this.selectedSkills,
            });
            loading.dismiss();

            // Do self evaluation if needed
            if (this.onboardingService.hasAutoEval()) {
                this.onboardingService.goTo('self-evaluation', true);
            } else {
                // skip self evaluation and go to contacts
                this.onboardingService.goTo('contacts', true);
            }
        });
    }

    close(){
        this.authData.data.user.is_onboarding_finished = true;
        this.onboardingService.onboardingFinished().subscribe();
        this.onboardingService.close();
        this.navCtrl.navigateRoot('/home');
    }
}
