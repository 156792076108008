import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {AuthData} from '../../../models/auth-status';
import {OnboardingService} from '../../../services/onboarding.service';
import {SwiperOptions} from 'swiper/types';
import {SwiperComponent} from 'swiper/angular';

@Component({
    selector: 'app-onboarding-dashboard',
    templateUrl: './onboarding-dashboard.page.html',
    styleUrls: ['./onboarding-dashboard.page.scss'],
})
export class OnboardingDashboardPage  {
    @ViewChild('slides', {static: false}) slides: SwiperComponent;
    @ViewChild('slidePagination', {static: false}) slidePagination: ElementRef;
    authData: AuthData;
    swiperConfig: SwiperOptions;

    constructor(
        private readonly authService: AuthService,
        private readonly onboardingService: OnboardingService,
    ) { }

    ionViewWillEnter() {
        this.authData = this.authService.authStatus.value.authData;
        this.swiperConfig = {
            // height:
            // autoHeight: true,
            pagination: {
                el: this.slidePagination.nativeElement,
            }
        };
    }

    close(){
        this.authData.data.user.is_onboarding_dashboard_finished = true;
        this.onboardingService.onboardingFinished('dashboard').subscribe();
        this.onboardingService.close();
    }

    canSlideBack() {
        return !this.slides.swiperRef.isBeginning;
    }

    slideBack() {
        this.slides.swiperRef.slidePrev();
    }

    canSlideForward(): boolean {
        return !this.slides.swiperRef.isEnd;
    }

    slideForward() {
        this.slides.swiperRef.slideNext();
    }
}
