import {LoadingController} from '@ionic/angular';

export class LoadingHelper {

    static loadingCtrl: LoadingController;
    static loader: HTMLIonLoadingElement;

    static init(loadingCtrl: LoadingController) {
        this.loadingCtrl = loadingCtrl;
    }

    static async open(message: string = null): Promise<void> {
        if (!this.loadingCtrl || this.loader) {
            return;
        }
        this.loader = await this.loadingCtrl.create({message});
        await this.loader.present();
    }

    static async close(): Promise<void> {
        if (!this.loader || !this.loadingCtrl) {
            return;
        }
        await this.loader.dismiss();
        this.loader = null;
    }

}
