/* tslint:disable:variable-name */

import {Badge, Gamification, SkillBadgeCriterion, SkillStatistics} from './gamification';

export class SkillPath {
    id: number;
    title: string;
    name: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get label() {
        return this.title || this.name;
    }
}

export class SkillQuestion {
    id: number;
    title: string;
    title_self_evaluation: string;
    behavior: string;
    score: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class SkillCategoryTranslation {
    title: string;
    locale: string;
}

export type Color = 'blue' | 'green' | 'yellow' | 'purple' | 'pink' | 'turquoise';

export class SkillCategory {
    current_locale: string;
    default_locale: string;
    id: number;
    title: string;
    translations: SkillCategoryTranslation[];
    color: Color;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export type BadgeType = 'hard_skills' | 'soft_skills' | 'mad_skills';

export class SkillFamily {
    id: number;
    title: string;
    badge_type: BadgeType;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class SkillTranslation {
    locale: string;
    title: string;
    tags: string[];
}


export interface SkillGroup {
    skills?: Skill[];
    skillStatistics?: SkillStatistics[];
    category: SkillCategory;
}

export interface SkillFamilyGroup {
    family: SkillFamily;
    categories: {skills: Skill[], category: SkillCategory}[];
}

export interface StatFamilyGroup {
    family: SkillFamily;
    categories: {stats: SkillStatistics[], category: SkillCategory}[];
}

export class Skill {
    category: SkillCategory;
    family: SkillFamily;
    selected = false;
    current_locale: string;
    default_locale: string;
    icon: string;
    id: number;
    is_event: boolean;
    linked_skills: number[];
    questions: SkillQuestion[];
    tags: string[];
    title: string;
    translations: SkillTranslation[];
    skill_badge_criterions: SkillBadgeCriterion[];
    stat?: SkillStatistics;
    description: string;
    displayOrder: number;
    min_requests: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.category = obj?.category ? new SkillCategory(obj.category) : null;
            this.family = obj?.family ? new SkillFamily(obj.family) : null;
            this.skill_badge_criterions = (obj.skill_badge_criterions || []).map(c => new SkillBadgeCriterion(c));
        }
    }

    static groupSkillByCategory(skills: Skill[]) {
        const groups: SkillGroup[] = [];
        skills.forEach(skill => {
            const group = groups.find(g => g.category.id === skill.category.id);
            if (group) {
                group.skills.push(skill);
            } else {
                groups.push({
                    skills: [skill],
                    category: skill.category,
                });
            }
        });

        return groups;
    }

    static groupSkillByFamily(skills: Skill[]): SkillFamilyGroup[] {
        // Group by family
        const groups: SkillFamilyGroup[] = [];
        skills.forEach(skill => {
            const family = skill.family || new SkillFamily({title: 'Soft skills', badge_type: 'soft_skills'});
            const group = groups.find(g => g.family?.title === family.title);
            if (group) {
                const category = group.categories.find(c => c.category.id === skill.category.id);
                if (category) {
                    category.skills.push(skill);
                } else {
                    group.categories.push({
                        skills: [skill],
                        category: skill.category,
                    });
                }
            } else {
                groups.push({
                    family,
                    categories: [{
                        skills: [skill],
                        category: skill.category,
                    }],
                });
            }
        });

        return Gamification.sortFamily<SkillFamilyGroup>(groups);
    }

    static skillGroupByFamily(skillGroups: SkillGroup[]): SkillFamilyGroup[] {
        const skills: Skill[] = [];
        for (const skillGroup of skillGroups) {
            skills.push(...skillGroup.skills);
        }

        return Skill.groupSkillByFamily(skills);
    }

    static groupStatByFamily(stats: SkillStatistics[]): StatFamilyGroup[] {
        // Group by family
        const groups: StatFamilyGroup[] = [];
        stats.forEach(stat => {
            const family = stat.skill.family || new SkillFamily({title: 'Soft skills', badge_type: 'soft_skills'});
            const group = groups.find(g => g.family?.title === family.title);
            if (group) {
                const category = group.categories.find(c => c.category.id === stat.skill.category.id);
                if (category) {
                    category.stats.push(stat);
                } else {
                    group.categories.push({
                        stats: [stat],
                        category: stat.skill.category,
                    });
                }
            } else {
                groups.push({
                    family,
                    categories: [{
                        stats: [stat],
                        category: stat.skill.category,
                    }],
                });
            }
        });

        return Gamification.sortFamily<StatFamilyGroup>(groups);
    }

    canRequestFeedbacks(): boolean {
        return this.skill_badge_criterions
                .some(c => c.modality === 'opened_feedbacks' || c.modality === 'closed_feedbacks')
            || this.skill_badge_criterions.length === 0;
    }

    isSoftSkill() {
        return !this.family ? true : this.family?.badge_type === 'soft_skills';
    }

    isHardSkill() {
        return this.family?.badge_type === 'hard_skills';
    }

    isMadSkill() {
        return this.family?.badge_type === 'mad_skills';
    }

    hasFeedbackCriteria(criterias: SkillBadgeCriterion[] = null) {
        return this.skill_badge_criterions.length === 0
       || (criterias || this.skill_badge_criterions).some(c => c.modality === 'opened_feedbacks' || c.modality === 'closed_feedbacks');
    }

    hasOpenedFeedbackCriteria() {
        return this.skill_badge_criterions.length === 0
            || this.skill_badge_criterions.some(c => c.modality === 'opened_feedbacks')
            ;
    }

    gradesOnly() {
        // If there is only note_* criterions
        return this.skill_badge_criterions.every(c => c.modality.startsWith('note_')) && this.skill_badge_criterions.length > 0;
    }

    feedbacksOnly() {
        // If no criterions or only feedback criterions
        if (this.skill_badge_criterions.length === 0) {
            return true;
        }

        return this.skill_badge_criterions.every(c => c.modality === 'closed_feedbacks' || c.modality === 'opened_feedbacks');
    }

    mixedCriteria() {
        return !this.gradesOnly() && !this.feedbacksOnly();
    }

    hasClosedFeedbackCriteria() {
        return this.skill_badge_criterions.some(c => c.modality === 'closed_feedbacks');
    }

    hasGradeCriteria() {
        return this.skill_badge_criterions.some(c => c.modality.startsWith('note_'));
    }
}

export class SkillStat {
    categoryId: number;
    name: string;
    feedbackCount: number;
    from: string;
    to: string;
    school: string;
    program: string;
    type: 'skill' | 'badge';
    grades: {
        skillId: number,
        link: string,
        text: string,
        grade: number,
    }[];
    pro: string;
    grade: number;
    schoolColor: string;
    badges: Badge[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.badges = (obj.badges || []).map(b => new Badge(b));
        }
    }
}

export class BadgeFamily {
    name: string;
    skills: SkillStat[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
