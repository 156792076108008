import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Credentials} from '../models/credentials';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiFormat} from '../../enums/api-format';
import {AuthData, AuthStatus} from '../models/auth-status';
import {StorageService} from './storage.service';
import {Platform} from '@ionic/angular';
import {InAppBrowser, ToolBarType} from '@capgo/inappbrowser';
import {Browser} from '@capacitor/browser';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    authStatus = new BehaviorSubject<AuthStatus>(null);
    private clientSecret: string;
    private clientId: string;

    constructor(
        private readonly storageService: StorageService,
        private readonly apiService: ApiService,
        private readonly platform: Platform,
    ) { }

    public retrievePassword(email): Observable<boolean> {
        return this.apiService
            .post('/api/forgottenpassword', {email}, ApiFormat.JSON, false)
            .pipe(map(res => true, err => false));
    }

    public revokeTokens(){
        return this.apiService.get('/api/revoke-tokens').pipe(map(response => true));
    }

    public getAPICredentials(): Observable<boolean> {
        return this.apiService
            .get('/api/getcredentials', ApiFormat.JSON, false)
            .pipe(map(
                (credentials: Credentials) => {
                    this.clientId = credentials.clientId;
                    this.clientSecret = credentials.clientSecret;
                    return false;
                }
            ));
    }

    public login(credentials: {email: string, password: string}): Observable<AuthData> {
        const body = {
            grant_type: 'password',
            client_id: this.clientId,
            client_secret: this.clientSecret,
            username: credentials.email,
            password: credentials.password
        };

        return this.apiService
            .post<AuthData>('/oauth/v2/token' , body, ApiFormat.JSON, false)
            .pipe(map((response: any) => {
                const authData = new AuthData('-', '-', response.access_token);
                this.apiService.saveToken(response.access_token, response.user_id, credentials.email);
                return authData;
            }));
    }

    public async checkLicenseAndCossAccess(): Promise<boolean> {
        try {
            const [checkLicense, checkCoss] = await Promise.all([
                this.checkLicense(),
                this.checkCossAccess(),
            ]);

            return checkLicense && checkCoss;
        } catch (err) {
            return false;
        }
    }

    public async checkCossAccess(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.apiService
                .post<{ allowed: boolean }>('/api/check/coss', {source: 'coss'}, ApiFormat.JSON)
                .pipe(map(res => {
                    return res?.allowed;
                }))
                .subscribe({
                    next: resolve,
                    error: reject
                });
        });
    }

    public async checkLicense(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.apiService
                .post<{ access: boolean }>('/api/check/licence/access', {source: 'coss'}, ApiFormat.JSON)
                .pipe(map(res => {
                    return res?.access;
                }))
                .subscribe({
                    next: resolve,
                    error: reject
                });
        });
    }

    public checkSSO(matricule: string): Observable<{status: boolean, link: string}> {
        return this.apiService.post<{status: boolean, link: string}>('/api/check/saml', {source: 'app', matricule});
    }

    public async openSSO(link: string): Promise<{saml: boolean, token: string, email: string}> {
        return new Promise(resolve => {
            let resolved = false;
            if (this.platform.is('capacitor')) {
                InAppBrowser.openWebView({
                    url: link,
                    title: 'SSO',
                    toolbarType: ToolBarType.DEFAULT,
                });
                InAppBrowser.addListener('urlChangeEvent', event => {
                    if (event.url.startsWith('https://app.globalcoss.com')) {
                        // In url find parameters saml (boolean) and token (string) and email (string)
                        const parsed = new URL(event.url);
                        const saml = parsed.searchParams.get('saml') === 'true';
                        const token = parsed.searchParams.get('token');
                        const email = parsed.searchParams.get('email');

                        // Close browser
                        InAppBrowser.close();
                        InAppBrowser.removeAllListeners();
                        resolved = true;

                        // Handle error
                        if (!saml || !token || !email) {
                            return resolve({saml: false, token: null, email: null});
                        }
                        resolve({saml, token, email});
                    }
                });
                InAppBrowser.addListener('closeEvent', event => {
                    // Remove listeners
                    InAppBrowser.removeAllListeners();
                    if (!resolved) {
                        resolve({saml: false, token: null, email: null});
                    }
                });
            } else {
                Browser.open({url: link, windowName: '_self'});
                resolve(null);
            }
        });
    }

    public async isLogged(): Promise<boolean> {
        const credentials = await this.storageService.get('user_credentials');
        if (!credentials) {
            this.authStatus.next(new AuthStatus(null, false));
            return false;
        }
        this.apiService.saveToken(credentials.token, credentials.userId, credentials.userEmail);

        return true;
    }

    public logout(): Promise<boolean> {
        this.authStatus.next(new AuthStatus(null, false, false));

        // Promise all is used to wait for all promises to be resolved
        return Promise.all([
            this.storageService.remove('user_credentials'),
            this.storageService.remove('session_data'),
            this.storageService.remove('contacts'),
        ]).then(
            (data) => true,
            (error) => false
        );
    }
}
