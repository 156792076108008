/* tslint:disable:variable-name */
import {StringUtils} from '../utils/string-utils';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ChallengeMultiChoiceValidator} from "../validator/challenge-multi-choice.validator";
import {Skill} from "./skill";

/**
 * The type of the challenge:
 * multiple_choice: the user has to choose one or more answers among a list of answers (check the correct_answers_id field)
 * open_text: the user has to write a text (check the correct_answers_text field)
 * holes: the user has to fill the holes in a text (check the correct_answers_text field)
 * single_choice: the user has to choose one answer among a list of answers (check the correct_answers_id field)
 * challenge: the user has to perform a challenge (no answer required)
 */
export type ChallengeType =  'multiple_choice' | 'open_text' | 'holes' | 'single_choice' | 'challenge';

/**
 * The status of the challenge:
 * waiting: the challenge is available and user didn't answer yet ("défis à réaliser")
 * accepted: the challenge was accepted by the user and user has 7 days to validate the challenge ("défis en cours")
 * declined: the challenge was declined by the user (not listed on the app)
 * performed: the challenge was performed by the user (not listed on the app)
 * to_validate: 7 days passed and the challenge is waiting for validation by the user ("défis à valider")
 */
export type ChallengeStatus = 'pending' | 'accepted' | 'declined' | 'performed' | 'to_validate';

export class Challenge {

    static readonly DELIMITER = '____';

    type: ChallengeType;
    status: ChallengeStatus;
    correct_answers_id: string[];
    correct_answers_text: string[];
    answers: {id: string, text: string, type?: 'question'|'select', values?: {label: string}[], correct_answer: string}[];
    question: string;
    good_answer_description: string;
    bad_answer_description: string;
    default_answer_description: string;
    skill_title: string;
    skill_id: number;
    challenge_id: number;
    days: number; // Days elapsed since the challenge was accepted
    accepted_at: string; // Date when the challenge was accepted
    title: string; // Only for type "challenge"
    correct_answer: string;
    switchable_holes: boolean; // Only for type "holes", if true, the user can switch the answers

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get daysLeft(): number {
        return 7 - (this.days || 0);
    }

    get daysDone(): number {
        return (this.days || 0) - 7;
    }

    get icon(): string {
        return this.type === 'challenge' ? 'leadership' : 'be-constructive';
    }

    get description(): string {
        let text = '';
        if (this.type === 'challenge') {
            text = this.title;
        } else {
            text = this.question;
        }

        // Add space before and after Challenge.DELIMITER
        text = text.replace(new RegExp(Challenge.DELIMITER, 'g'), ` ${Challenge.DELIMITER} `);
        // Remove all double spaces
        text = text.replace(/  +/g, ' ');

        return text.length > 50 ? `${text.substring(0, 50)}...` : text;
    }

    get displayCorrectAnswers(): string {
        if (this.type === 'holes') {
            const parts = this.question.split(Challenge.DELIMITER);
            let text = '';
            // Create one form control for each input
            parts.forEach((part, index) => {
                if (index !== 0) {
                    if (this.answers.length > 1) {
                        text += `${index}.&nbsp;`;
                    }
                    text += `<span class="text-green">${this.answers[index - 1].correct_answer}</span>&nbsp;`;
                }
                text += part.trim() + '<br>';
            });

            return text;
        }

        if (this.type === 'open_text') {
            return `<span class="text-green">${this.correct_answer?.toUpperCase()}</span>`;
        }

        throw new Error('Not implemented');
    }


    validateAnswer(value: any): boolean {
        switch (this.type) {
            case 'single_choice':
                return this.validateSingleChoiceAnswer(value);
            case 'multiple_choice':
                return this.validateMultiChoiceAnswer(value);
            case 'holes':
                return this.validateHolesAnswer(value);
            case 'open_text':
                return this.validateOpenTextAnswer(value);
        }
    }

    createForm(): [FormGroup, QuestionPart[]] {
        switch (this.type) {
            case 'single_choice':
                return this.createSingleChoiceForm();
            case 'multiple_choice':
                return this.createMultiChoiceForm();
            case 'holes':
                return this.createHolesForm();
            case 'open_text':
                return this.createOpenTextForm();
            case 'challenge':
                return [null, null];
        }
    }

    private createMultiChoiceForm(): [FormGroup, QuestionPart[]] {
        const questionParts: QuestionPart[] = [];
        // Create one form control for each possible answer (checkbox)
        this.answers.forEach(answer => {
            questionParts.push({
                id: answer.id,
                text: answer.text,
                formControlName: answer.id,
            });
        });
        const form = new FormGroup({});
        questionParts.forEach(part => {
            form.addControl(part.formControlName, new FormControl(false));
        });
        form.addValidators(ChallengeMultiChoiceValidator);
        return [form, questionParts];
    }

    private createSingleChoiceForm(): [FormGroup, QuestionPart[]] {
        const questionParts: QuestionPart[] = [];
        // Create one form control (radio group) for each possible answer
        this.answers.forEach(answer => {
            questionParts.push({
                id: answer.id,
                text: answer.text,
                formControlName: 'choice'
            });
        });
        const form = new FormGroup({
            choice: new FormControl(null, Validators.required)
        });
        return [form, questionParts];
    }

    private createOpenTextForm(): [FormGroup, QuestionPart[]] {
        const questionParts = [{
            text: this.question,
            hasInput: true,
            formControlName: 'input',
        }];
        const form = new FormGroup({
            input: new FormControl('', Validators.required)
        });
        return [form, questionParts];
    }

    private createHolesForm(): [FormGroup, QuestionPart[]] {
        const parts = this.question.split(Challenge.DELIMITER);
        const questionParts: QuestionPart[] = [];
        // Create one form control for each input
        parts.forEach((part, index) => {
            questionParts.push({
                id: `input_${index}`,
                text: part.trim(),
                hasInput: index !== 0,
                formControlName: `input_${index - 1}`,
                type: this.answers[index - 1]?.type,
                values: this.answers[index - 1]?.values?.map(value => ({label: value.label, value: value.label, type: 'radio'}))
            });
        });
        const form = new FormGroup({});
        questionParts.forEach(part => {
            if (part.hasInput) {
                form.addControl(part.formControlName, new FormControl('', Validators.required));
            }
        });
        return [form, questionParts];
    }

    private validateSingleChoiceAnswer(value: any): boolean {
        return this.correct_answers_id.includes(value.choice);
    }

    private validateMultiChoiceAnswer(value: { [key: string]: boolean }): boolean {
        return this.correct_answers_id.every(id => value[id] === true)
            && Object.values(value).filter(v => v ===  true).length === this.correct_answers_id.length;
    }

    private validateHolesAnswer(value: any): boolean {
        console.log(this);
        if (this.switchable_holes === false) {
            // Check answers based on index in value
            return this.answers.every((answer, index) => {
                const input = value[`input_${index}`];
                return StringUtils.checkAnswerMatch(answer.correct_answer, input);
            });
        } else {
            // Get the answers, match it with at least one of the correct answers
            const answers = Object.keys(value).map(key => value[key]);
            return answers.every(answer => this.answers.some(a => StringUtils.checkAnswerMatch(a.correct_answer, answer)));
        }
    }

    private validateOpenTextAnswer(value: any): boolean {
        console.log(this);
        return StringUtils.checkAnswerMatch(this.correct_answer, value.input);
    }

    getAnswerDescription(isCorrect: boolean) {
        if (isCorrect) {
            return this.good_answer_description;
        } else {
            return this.bad_answer_description;
        }
    }
}

export interface QuestionPart {
    id?: string;
    text: string;
    formControlName: string;
    hasInput?: boolean;
    validators?: Validators[];
    type?: 'question'|'select';
    values?: {label: string, value: string, type: 'radio'}[];
}

export class ChallengeStat {
    skill: Skill;
    status: 'learning'|'memorized';
    score: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.skill = obj.skill ? new Skill(obj.skill) : null;
        }
    }
}
