import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {FeedbackPickEvaluatorPageRoutingModule} from './feedback-pick-evaluator-routing.module';

import {FeedbackPickEvaluatorPage} from './feedback-pick-evaluator.page';
import {TranslateModule} from '@ngx-translate/core';
import {SwiperModule} from 'swiper/angular';
import {ContactListComponent} from '../../../components/contact-list/contact-list.component';
import {SharedModule} from '../../../shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FeedbackPickEvaluatorPageRoutingModule,
        TranslateModule,
        SwiperModule,
        SharedModule
    ],
    exports: [
        ContactListComponent
    ],
    declarations: [FeedbackPickEvaluatorPage, ContactListComponent]
})
export class FeedbackPickEvaluatorPageModule {}
