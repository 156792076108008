<ion-content>
	<div class="container" [formGroup]="formGroup" *ngIf="formGroup">
		<div class="d-flex justify-content-between align-items-start">
			<h1>{{ 'add-contact.AJOUT_EVALUATEUR' | translate }}</h1>
			<div class="d-flex justify-content-end text-green fs-2 clickable" (click)="close()">
				<ion-icon name="close-outline"></ion-icon>
			</div>
		</div>

		<!-- Firstname -->
		<div class="form-group pt-2 pb-1">
			<label>{{ 'add-contact.PRENOM' | translate }}</label>
			<input type="text" class="form-control" formControlName="firstname">
		</div>
		<!-- Lastname -->
		<div class="form-group pb-1">
			<label>{{ 'add-contact.NOM' | translate }}</label>
			<input type="text" class="form-control" formControlName="lastname">
		</div>
		<!-- Email -->
		<div class="form-group pb-1">
			<label>{{ 'add-contact.EMAIL' | translate }}</label>
			<input type="email" class="form-control" formControlName="email">
		</div>
		<!-- Type -->
		<div class="form-group pb-1">
			<label>{{ 'add-contact.TYPE' | translate }}</label>
			<ion-select class="select-control"
						formControlName="type"
						[placeholder]="'add-contact.SELECTION_TYPE'|translate"
						[cancelText]="'CANCEL'|translate"
						[okText]="'OK'|translate"
			>
				<ion-select-option value="pro">{{ 'contacts.pro'|translate }}</ion-select-option>
				<ion-select-option value="external_pro">{{ 'contacts.external_pro'|translate }}</ion-select-option>
				<ion-select-option value="sport_asso">{{ 'contacts.sport_asso'|translate }}</ion-select-option>
				<ion-select-option value="other">{{ 'contacts.other'|translate }}</ion-select-option>
			</ion-select>
		</div>
		<!-- Title -->
		<div class="form-group pb-1">
			<label>{{ 'add-contact.TITRE' | translate }}</label>
			<input type="email" class="form-control" formControlName="title" [placeholder]="'add-contact.TITRE_PLACEHOLDER'|translate">
		</div>

		<div class="d-flex gap-2 mt-4">
			<button class="btn btn-block btn-outlined btn-green btn-rounded" (click)="close()">{{ 'CANCEL'|translate }}</button>
			<button class="btn btn-block btn-primary btn-green btn-rounded"  [disabled]="formGroup.invalid" (click)="addContact()">{{ 'ADD'|translate }}</button>
		</div>

	</div>
</ion-content>
