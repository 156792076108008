import {Badge} from './gamification';

export class Wallet {
    id: number;
    title: string;
    // date: string;
    token: string;
    createdAt: string;
    badges: Badge[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.badges = (obj?.badges || []).map(b => new Badge(b));
        }
    }
}
