import {Injectable} from '@angular/core';
import {TranslateService as BaseTranslateService} from '@ngx-translate/core';
import {UserService} from "./user.service";
import {SettingsService} from "./settings.service";
import {Device, GetLanguageCodeResult} from "@capacitor/device";
import {Platform} from "@ionic/angular";
import {ContactListType} from "../models/contact";

@Injectable({
    providedIn: 'root'
})
export class TranslateService {

    private locale = 'fr';
    private authorizedLocales = ['fr', 'en'];
    private initialized = false;

    constructor(
        private readonly translate: BaseTranslateService,
        private readonly settingsService: SettingsService,
        private readonly platform: Platform,
    ) {
        this.translate.setDefaultLang('fr');
    }

    private setDefaultLocale(): void {
        this.translate.use('fr');
        this.locale = 'fr';
        this.initialized = true;
    }

    private doChangeLocale(locale: string): void {
        // If the locale is the same and the service is already initialized, do nothing
        if (locale === this.locale && this.initialized) {
            return;
        }
        // Initialize the service
        this.initialized = true;

        // If the locale is not authorized, set the default locale
        if (this.authorizedLocales.includes(locale)) {
            this.locale = locale;
            this.translate.use(locale);
        } else {
            this.setDefaultLocale();
        }

        // Save the locale
        this.settingsService.setSettings('language', this.locale);
    }

    async init() {
        // Get the saved locale
        const savedLocale = this.settingsService.getSettings('language');
        if (savedLocale) {
            return this.doChangeLocale(savedLocale);
        }

        // Get locale from the device
        if (this.platform.is('cordova')) {
             const languageResult = await Device.getLanguageCode();
             if (languageResult?.value) {
                 return this.doChangeLocale(languageResult.value.toLowerCase());
             }
        }

        // Get locale from the browser
        const locale = this.translate.getBrowserLang();
        if (locale) {
            return this.doChangeLocale(locale);
        }

        // Set the default locale
        return this.setDefaultLocale();
    }

    getLocale(): string {
        return this.locale;
    }

    get(key: string|string[], params?: any, locale?: string): any {
        if (locale) {
            return this.translate.instant(key, params);
        }
        return this.translate.instant(key, params);
    }

    changeLocale(locale: string) {
        return this.doChangeLocale(locale);
    }

    getContactListName(list: ContactListType): string {
        return this.get(`contacts.${list}`);
    }
}
