import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import {ApiFormat} from '../../enums/api-format';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(
      private readonly apiService: ApiService,
      private readonly authService: AuthService,
  ) { }

  private getLocale() {
    let locale = this.authService.authStatus.value?.authData.data.user.locale;
    if (['ar', 'fa', 'es', 'de'].includes(locale)) {
      locale = 'en';
    }
    return locale;
  }

  public getCGU() {
    return this.apiService.get<string>('/cgu.php?locale=' + this.getLocale(), ApiFormat.TEXT, false);
  }

  public getPolConf() {
    return this.apiService.get<string>('/pol_conf.php?locale=' + this.getLocale(), ApiFormat.TEXT, false);
  }

  public linkWithDomoscio(linkType){
    return this.apiService
        .post('/api/link/domoscio', {linkType})
        .pipe(map((response: any) => response.status));
  }
}
