import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage-angular';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {SessionData} from '../models/auth-status';
import {Skill, SkillCategory, SkillGroup} from '../models/skill';
import {UserService} from './user.service';
import {AlertController} from '@ionic/angular';
import {marker as _} from '@colsen1991/ngx-translate-extract-marker';
import {TranslateService} from './translate.service';
import {LoadingHelper} from "../utils/loading.helper";

@Injectable({
    providedIn: 'root'
})
export class SkillService {

    skillsGroups: SkillGroup[];

    constructor(
        private readonly storage: Storage,
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
    ) { }


    init() {
        this.storage.get('skills', ).then((skills: SkillGroup[]) => {
            if (skills) {
                this.skillsGroups = skills;
            }
        });
    }

    /** Get skills from api (feedback request) */

    public getSkillGroups(){
        return this.apiService.get<any[]>('/api/skills')
            .pipe(map(response => {
                const result: SkillGroup[] = [];
                for (const cat of response) {
                    result.push({
                        category: new SkillCategory({id: cat.id, title: cat.title}),
                        skills: cat.skills.map(s => new Skill(s)),
                    });
                }
                this.skillsGroups = result;
                this.storage.set('skills', result);
                return result;
            }));
    }

    public postUserSkills(skills: { id: number }[]){
        return this.apiService
            .post<SessionData>('/api/user/skills', {skills})
            .pipe(map(response => {
                return this.userService.saveUser(response);
            }));
    }

    public async checkUserSkill(skill: Skill): Promise<void> {
        // Create loading
        await LoadingHelper.open();
        return new Promise(async (resolve, reject) => {
            const skills = this.authService.authStatus.value?.authData?.data?.user.skills.map(s => ({id: s.id}));
            // If skill is already set, do nothing
            if (skills.find(s => s.id === skill.id)) {
                resolve();
                return;
            }

            // Add skill to user
            skills.push({id: skill.id});
            this.postUserSkills(skills).subscribe({
                next: () => {
                    this.userService.updateUserInfo().subscribe({
                        next: () => {
                            resolve();
                        },
                        error: err => {
                            reject(err);
                        }
                    });
                },
                error: err => {
                    reject(err);
                }
            });
        });
    }

    public canPickMoreSkills(max = 5): boolean
    {
        const limit = this.authService.authStatus.value?.authData?.data?.skillsLimit || max;
        return this.authService.authStatus.value?.authData?.data?.user.skills.length < limit;
    }

    public countSkillsToPick(max = 5): number {
        const limit = this.authService.authStatus.value?.authData?.data?.skillsLimit;
        const skills = this.authService.authStatus.value?.authData?.data?.user.skills?.length || 0;
        return limit - skills;
    }

    public resetUserSkills(selectedSkills: { skills: {id: number}[] }): Promise<void> {
        return new Promise(resolve => {
            return this.apiService
                .post('/api/user/resets/skills', selectedSkills)
                .subscribe({
                    next: response => {
                        this.userService.updateUserInfo().subscribe({
                            next: () => {
                                resolve();
                            },
                            error: err => {
                                resolve();
                            }
                        });
                    },
                    error: err => {
                        resolve();
                    }
                });
        });
    }

    /**
     * Dashboard request
     */
    public dashboardAccess(){
        return this.apiService.get<any>('/api/user/dashboard/access');
    }

    pickSkillPath(selectedPath: number) {
        return this.apiService.patch<any>('/api/choose/skillspath', {skills_path_id: selectedPath})
            .pipe(tap(response => {
                this.userService.updateUserInfo().subscribe();
            }));
    }

    async checkUserSkillLimit(selectedSkills: Skill[]): Promise<boolean> {
        const limit = this.authService.authStatus.value?.authData?.data?.skillsLimit || 2;
        // Check that the user has selected the right number of skills
        if (selectedSkills.length > limit) {
            const alert = await this.alertCtrl.create({
                header: this.translateService.get(_('skills.TITLE_1')),
                subHeader: this.translateService.get(_('skills.MESSAGE_1'), {param1: limit}),
                buttons: [this.translateService.get(_('skills.BUTTON_1'))]
            });
            await alert.present();
            return false;
        }

        return true;
    }
}
