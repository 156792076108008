<ion-content>
	<div class="container p-relative">
		<div id="terms-content" [innerHTML]="content"></div>
	</div>

	<button class="btn btn-green btn-block btn-rounded btn-fixed-bottom"
			type="button"
			(click)="next()"
			slot="fixed"
	>
		{{ 'onboarding-terms.ACCEPTER' | translate }}
	</button>
</ion-content>


