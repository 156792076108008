import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {NavController} from "@ionic/angular";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
      private readonly authService: AuthService,
      private readonly navCtrl: NavController,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        console.error('interceptor error', err);
        this.authService.logout();
        this.navCtrl.navigateRoot('/login');
      }
      return throwError(() => err);
    }));
  }
}
