import { Pipe, PipeTransform } from '@angular/core';
import {FeedbackReceived} from '../models/feedback';

@Pipe({
  name: 'question'
})
export class QuestionPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.replace(/_NAME_/g, args[0] as string);
  }

}
