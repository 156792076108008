import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {Contact} from '../../models/contact';
import {TranslateService} from '../../services/translate.service';
import {StorageService} from '../../services/storage.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {ShareService} from '../../services/share.service';
import {FeedbackService} from "../../services/feedback.service";

@Component({
    selector: 'app-fb-request-notify-modal',
    templateUrl: './fb-request-notify-modal.component.html',
    styleUrls: ['./fb-request-notify-modal.component.scss'],
})
export class FbRequestNotifyModalComponent  implements OnInit {

    @Input() contacts: Contact[];
    dontNotify = false;

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly translateService: TranslateService,
        private readonly feedbackService: FeedbackService,
        private readonly shareService: ShareService
    ) { }

    ngOnInit() {}

    close() {
        if (this.dontNotify) {
            // Store the don't notify value
            this.feedbackService.doNotAskNotify();
        }
        this.modalCtrl.dismiss();
    }

    async send() {
        // Create the message content
        const subject = this.translateService.get(_('fb-notify.MAIL_SUBJECT'));
        const body = this.translateService.get(_('fb-notify.MAIL_BODY'));
        const to = this.contacts.map(c => c.email);

        await this.shareService.shareByMail(to, subject, body);
        this.close();
    }

    toggleDontNotify() {
        this.dontNotify = !this.dontNotify;
    }
}
