/* tslint:disable:variable-name */

import {Skill, SkillPath} from './skill';
import {UserType} from './gamification';

export class User {
    id: string;
    email: string;
    locale: string;
    firstname: string;
    lastname: string;
    type?: 'RECIPIENT_PRO' | 'RECIPIENT_EXTERNAL_PRO' | 'RECIPIENT_SPORT_ASSO' | 'RECIPIENT_OTHER';
    title?: string;
    status: boolean;
    is_onboarding_dashboard_finished: boolean;
    is_onboarding_challenge_finished: boolean;
    is_onboarding_answer_finished: boolean;
    roles: string[];
    skills: Skill[];
    domoscio_linked: boolean;
    is_onboarding_finished: boolean;
    new_feedback_count: number;
    new_badge_count: number;
    skillPaths: SkillPath[];
    onboarding?: {
        is_onboarding_finished: boolean;
    };
    skills_path: { id: number };

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.skills = (obj?.skills || []).map(s => new Skill(s));
        }
    }

    isType(type: UserType): boolean {
        switch (type) {
            case 'pros':
                return this.roles.includes('ROLE_COSS_RECIPIENT_PRO');
            case 'associative':
                return this.roles.includes('ROLE_COSS_RECIPIENT_ASSO');
            case 'external_pros':
                return this.roles.includes('ROLE_COSS_RECIPIENT_EXTERNAL_PRO');
            case 'school_members':
                return this.roles.includes('ROLE_COSS_TEACHER')
                    || this.roles.includes('ROLE_COSS_SCHOOL_REFERENT')
                    || this.roles.includes('ROLE_COSS_SCHOOL_ADMIN')
                    ;
            case 'tutor':
                return this.roles.includes('ROLE_COSS_TUTOR');
        }
    }
}
