import {Injectable} from '@angular/core';
import {Contact, ContactListType, ContactType} from '../models/contact';
import {Contacts, GetContactsOptions} from '@capacitor-community/contacts';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from './translate.service';
import {AuthService} from './auth.service';
import {marker as _} from '@colsen1991/ngx-translate-extract-marker';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {StringUtils} from '../utils/string-utils';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
        private readonly authService: AuthService,
        private readonly platform: Platform,
        private readonly apiService: ApiService,
    ) { }

    public contacts: Contact[] = [];
    public contactList: Contact[] = [];

    static getPosition(type: ContactType) {
        switch (type) {
            case 'tutor':
                return 0;
            case 'pro':
                return 1;
            case 'external_pro':
                return 2;
            case 'school_referent':
            case 'school_admin':
            case 'teacher':
                return 3;
            case 'sport_asso':
                return 4;
            default:
                return 5;
        }
    }

    async authorize(): Promise<boolean> {
        let perm = await Contacts.checkPermissions();
        if (perm.contacts === 'prompt') {
            perm = await Contacts.requestPermissions();
        }

        return perm.contacts === 'granted';
    }

    async getDeviceContacts(): Promise<Contact[]> {
        // If we are running in a browser, return an empty array
        if (!this.platform.is('capacitor')) {
            return [];
        }

        // First check if we have permission
        const hasPermission = await this.authorize();
        if (!hasPermission) {
            return [];
        }

        const authData = this.authService.authStatus.value.authData;

        // Get the contacts
        const options: GetContactsOptions = {
            projection: {name: true, emails: true},
        };

        try {
            const {contacts} = await Contacts.getContacts(options);
            const results: Contact[] = [];
            for (const c of contacts) {
                // Check if we have a name
                if (!c.name?.family || !c.name?.given) {
                    continue;
                }
                // Check if the email is not the user's email
                const emails = (c.emails || []).filter(e => e.address !== authData.data.user.email);
                for (const email of emails) {
                    // Check if the email is valid
                    if (!RegExp(StringUtils.EMAIL_REGEX).test(email.address)) {
                        continue;
                    }

                    // Create the contact
                    results.push(new Contact({
                        firstname: c.name.given,
                        lastname: c.name.family,
                        email: email.address,
                        checked: false,
                        type: null,
                        title: null,
                        source: 'device',
                    }));
                }
            }

            return results;
        } catch (e) {
            return [];
        }
    }

    filterContacts(contacts: Contact[], query: string) {
        if (query && query.trim() !== '') {
            query = query.toLowerCase();
            return contacts.filter((contact) =>
                // Search by firstname, lastname or email
                contact.firstname?.toLowerCase().indexOf(query) > -1 ||
                contact.lastname?.toLowerCase().indexOf(query) > -1 ||
                contact.email?.toLowerCase().indexOf(query) > -1
            );
        }

        return contacts;
    }

    sortContacts(contacts: Contact[]): void {
        // Sort the contacts
        contacts.sort((a, b): number => {
            // Sort by checked
            if (a.checked && !b.checked) {
                return -1;
            }
            if (!a.checked && b.checked) {
                return 1;
            }


            // Sort by contact type
            const aType: number = ContactService.getPosition(a.type);
            const bType: number = ContactService.getPosition(b.type);
            // Sort by shorter type
            if (aType !== bType) {
                return aType - bType;
            }

            // Sort by name
            const aLastname = a.lastname?.toLowerCase() || 'zzzz';
            const bLastname = b.lastname?.toLowerCase() || 'zzzz';

            // Sort by lastname
            return aLastname.localeCompare(bLastname);
        });
    }

    getApiContacts(checkType?: ContactType): Promise<Contact[]> {
        const tutor = this.authService.authStatus.value.authData.data.learningPeriod?.tutor;
        return new Promise(resolve => {
            this.apiService.get<{ suggestions: any[] }>('/api/user/history')
                .pipe(map(resp => {
                    const items = resp.suggestions;
                    const contacts = [];
                    for (const i of items) {
                        const contact = new Contact();
                        const roles = i.roles ? Array.isArray(i.roles) ? i.roles : Object.values(i.roles) : [];
                        if (tutor && tutor.email === i.email) {
                            contact.type = 'tutor';
                        } else {
                            // Custom contacts
                            switch (i.type) {
                                case 'RECIPIENT_PRO':
                                    contact.type = 'pro';
                                    break;
                                case 'RECIPIENT_EXTERNAL_PRO':
                                    contact.type = 'external_pro';
                                    break;
                                case 'RECIPIENT_SPORT_ASSO':
                                    contact.type = 'sport_asso';
                                    break;
                                case 'RECIPIENT_OTHER':
                                    contact.type = 'other';
                                    break;
                                default:
                                    switch (true) {
                                        case roles.includes('ROLE_COSS_SCHOOL_REFERENT'):
                                            contact.type = 'school_referent';
                                            break;
                                        case roles.includes('ROLE_COSS_SCHOOL_ADMIN'):
                                            contact.type = 'school_admin';
                                            break;
                                        case roles.includes('ROLE_COSS_TEACHER'):
                                            contact.type = 'teacher';
                                            break;
                                        case roles.includes('ROLE_COSS_STUDENT'):
                                            contact.type = 'student';
                                            break;
                                    }
                                    break;
                            }
                        }

                        contact.id = i.id;
                        contact.firstname = i.firstname;
                        contact.lastname = i.lastname;
                        contact.email = i.email;
                        contact.source = 'api';
                        contact.roles = roles;
                        contact.checked = checkType === contact.type;
                        contact.title = i.title;

                        // Force title for tutor
                        if (tutor && tutor.email === i.email) {
                            contact.title = tutor.title || 'Tuteur';
                            contact.status = true;
                        } else {
                            contact.title = i.title;
                            contact.status = i.status;
                        }

                        contacts.push(contact);
                    }

                    return contacts;
                }))
                .subscribe({
                    next: contacts => {
                        resolve(contacts);
                    },
                    error: (err) => {
                        console.error(err);
                        resolve([]);
                    }
                });
        });
    }

    saveRecipients(newContacts: Contact[], type: ContactType) {
        // In this.contactList, remove all contacts of type 'type' and add the newContacts
        const contacts = this.contactList.filter(c => c.type !== type);
        contacts.push(...newContacts);

        // Save the contacts
        return this.apiService.patch('/api/save/recipients', {
            recipients: contacts.map(c => ({
                firstname: c.firstname,
                lastname: c.lastname,
                email: c.email,
                type: Contact.findApiType(c.type),
                title: c.title,
            }))
        });
    }

    async getAllContacts(type: ContactType, skillId: number = null): Promise<Contact[]> {
        // Load contacts from phone
        const [
            deviceContacts,
            apiContacts,
        ] = await Promise.all([
            this.getDeviceContacts(),
            this.getApiContacts(type)
        ]);

        let contacts = apiContacts;

        // Add device contacts
        deviceContacts.forEach((contact) => {
            if (!contacts.find(c => c.email === contact.email)) {
                contacts.push(contact);
            }
        });

        const user = this.authService.authStatus.value.authData.data.user;

        // Remove duplicates (same email) or if same email
        contacts = contacts.filter((contact, index, self) =>
            index === self.findIndex((c) => (c.email === contact.email))
            && contact.email !== user.email
        );

        // Set the last contact date
        if (skillId) {
            const sessionData = this.authService.authStatus.value.authData.data;
            const feedback = sessionData.alreadyRequested.find(r => r.skill === skillId);
            if (feedback) {
                for (const contact of contacts) {
                    const recipients = feedback.recipients.filter(r => r.email === contact.email);
                    if (recipients.length !== 0) {
                        // Find the latest date
                        contact.lastContact = Math.max(...recipients.map(c => new Date(c.date).getTime()));
                    }
                }
            }
        }

        // Update contact list
        this.contactList = contacts.filter(c => ['pro', 'external_pro', 'sport_asso'].includes(c.type));

        return contacts;
    }

    async checkMinRequiredContacts(contacts: Contact[], skillId: number = null, onboarding: boolean = false): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const data = this.authService.authStatus.value.authData.data;
            let minContacts = 5;
            const firstRequest = !data.alreadyRequestedSkill(skillId);
            if (!onboarding) {
                // Check if we have closed feedbacks
                let skill = data.user.skills.find(s => skillId === s.id);
                if (!skill) {
                    // Check in events
                    skill = data.events.find(s => s.id === skillId);
                }
                const closedFeedbacks = skill.skill_badge_criterions.filter(c => c.modality === 'closed_feedbacks');
                const isTutorFeedback = closedFeedbacks.length === 1 && closedFeedbacks[0].closed_feedbacks_detail === 'tutor';

                if (closedFeedbacks.length > 0) {
                    if (isTutorFeedback) {
                        // Tutor feedback
                        minContacts = 1;
                    } else {
                        // Closed feedbacks
                        minContacts = firstRequest ?  3 : 2;
                    }
                } else {
                    // Opened feedbacks
                    minContacts = firstRequest ? 5 : 3;
                }
            } else {
                // Onboarding
                minContacts = 5;
            }

            // If no request has been sent, we need at least 5 contacts, otherwise 3
            // let minContacts = (data.feedbacks.sent.length === 0) ? 5 : 3;
            if (contacts.length < minContacts) {
                this.alertCtrl.create({
                    header: this.translateService.get(_('contacts.SELECTIONNER_AU_MOINS'), {count: minContacts}),
                    buttons: [this.translateService.get(_('FERMER'))],
                }).then(alert => {
                    resolve(false);
                    alert.present();
                });
                return;
            } else {
                resolve(true);
            }
        });
    }

    public getContactTitle(type: ContactListType): string {
        switch (type) {
            case 'pro':
            case 'external_pro':
            case 'sport_asso':
            case 'school':
                return this.translateService.get(_('contacts.' + type));
            default:
                return this.translateService.get(_('contacts.other'));
        }
    }

    askValidation(type: 'pro' | 'external_pro' | 'sport_asso'): Observable<{ status: 'email_sent' | 'not_available' }> {
        return this.apiService.patch<{ status: 'email_sent' | 'not_available' }>('/api/recall/validator', {validator : type === 'pro' ? 'tutor' : 'coss' });
    }
}
