import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiFormat} from '../../enums/api-format';
import {map} from 'rxjs/operators';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private token: string;

    constructor(private readonly http: HttpClient, private storageService: StorageService) { }


    saveToken(token: string, userId: number, userEmail: string): void {
        this.token = token;
        this.storageService.set('user_credentials', {token, userId, userEmail});
    }

    getToken(): string {
        return this.token;
    }
    private createRequestHeaders(format: ApiFormat, auth: boolean): HttpHeaders
    {
        // Create the header object
        let headers = new HttpHeaders();

        // Set the authorization header
        if (auth) {
            headers = headers.set('Authorization', 'Bearer ' + this.token);
        }

        // Set the content type
        switch (format) {
            case ApiFormat.JSON:
                headers = headers.set('Content-Type', 'application/json');
                // headers = headers.set('Accept', 'application/json');
                break;
            case ApiFormat.FORM:
                headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
                // headers = headers.set('Accept', 'text/plain');
                break;
            case ApiFormat.MULTIPART:
                headers = headers.set('Content-Type', 'multipart/form-data');
                // headers = headers.set('Accept', 'application/json');
                break;
            case ApiFormat.TEXT:
                headers = headers.set('Content-Type', 'text/plain');
                // headers = headers.set('Accept', 'text/plain');
                break;
        }

        return headers;
    }

    post<T>(url: string, body: any, format: ApiFormat = ApiFormat.JSON, authenticated = true): Observable<T> {
        const responseType = this.getResponseType(format);
        // Text
        if (responseType === 'text') {
            return this.http.post(environment.apiEndpoint + url, body, {
                headers: this.createRequestHeaders(format, authenticated),
                responseType: 'text'
            }).pipe(map(res => res as T));
        }

        // Form data
        if (responseType === 'blob') {
            return this.http.post(environment.apiEndpoint + url, body, {
                headers: this.createRequestHeaders(format, authenticated),
                responseType: 'blob'
            }).pipe(map(res => res as T));
        }

        // JSON
        return this.http.post<T>(environment.apiEndpoint + url, body, {
            headers: this.createRequestHeaders(format, authenticated),
        });
    }

    get<T>(url: string, format: ApiFormat = ApiFormat.JSON, authenticated = true): Observable<T> {
        if (format === ApiFormat.TEXT) {
            return this.http.get(environment.apiEndpoint + url, {
                headers: this.createRequestHeaders(format, authenticated),
                responseType: 'text'
            }).pipe(map(res => res as T));
        }

        if (format === ApiFormat.MULTIPART) {
            throw new Error('Multipart not supported for GET requests');
        }

        return this.http.get<T>(environment.apiEndpoint + url, {
            headers: this.createRequestHeaders(format, authenticated)
        });
    }

    put<T>(url: string, body: any, format: ApiFormat= ApiFormat.JSON, authenticated = true): Observable<T> {
        return this.http.put<T>(environment.apiEndpoint + url, body, {
            headers: this.createRequestHeaders(format, authenticated)
        });
    }

    patch<T>(url: string, body: any, format: ApiFormat = ApiFormat.JSON, authenticated = true): Observable<T> {
        return this.http.patch<T>(environment.apiEndpoint + url, body, {
            headers: this.createRequestHeaders(format, authenticated)
        });
    }

    delete<T>(url: string, format: ApiFormat = ApiFormat.JSON, authenticated = true): Observable<T> {
        return this.http.delete<T>(environment.apiEndpoint + url, {
            headers: this.createRequestHeaders(format, authenticated)
        });
    }

    private getResponseType(format: ApiFormat): 'json' | 'blob' | 'text' {
        switch (format) {
            case ApiFormat.JSON:
                return 'json';
            case ApiFormat.MULTIPART:
                return 'blob';
            case ApiFormat.TEXT:
            case ApiFormat.FORM:
                return 'text';
        }
    }
}
