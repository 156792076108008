import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {FeedbackService} from '../../../services/feedback.service';
import {OnboardingService} from '../../../services/onboarding.service';
import {AuthData} from '../../../models/auth-status';
import {StorageService} from '../../../services/storage.service';
import {Contact} from '../../../models/contact';
import {OnboardingSelectionStored} from '../../../models/onboarding';
import {ModalController} from "@ionic/angular";
import {FbRequestNotifyModalComponent} from "../../fb-request-notify-modal/fb-request-notify-modal.component";

@Component({
    selector: 'app-onboarding-finished',
    templateUrl: './onboarding-finished.page.html',
    styleUrls: ['./onboarding-finished.page.scss'],
})
export class OnboardingFinishedPage {
    public selectedContacts: Contact[];
    public numberSelectedContacts;
    public skills;
    public recurrentSent = false;
    private authData: AuthData;
    private onboardingSelection: OnboardingSelectionStored;

    constructor(
        private readonly authService: AuthService,
        private readonly feedbackService: FeedbackService,
        private readonly onboardingService: OnboardingService,
        private readonly storageService: StorageService,
        private readonly modalCtrl: ModalController
    ) {
    }

    ionViewWillEnter() {
        this.init();
    }

    private async init() {
        this.authData = this.authService.authStatus.value.authData;
        this.onboardingSelection = await this.storageService.get('onboarding-selection');

        const selectedContacts = this.onboardingSelection?.contacts || [];
        this.numberSelectedContacts = selectedContacts.length - 5;
        this.selectedContacts = selectedContacts.slice(0, 5);

        this.authData.data.user.is_onboarding_finished = true;
        this.onboardingService.onboardingFinished('global').subscribe();

        // Ask if we should notify contacts
        this.askNotifyContacts(selectedContacts);
    }

    async askNotifyContacts(contacts: Contact[]) {
        // Notify user request
        this.modalCtrl.create({
            component: FbRequestNotifyModalComponent,
            componentProps: {contacts},
            cssClass: 'modal-auto-height'
        }).then((notifyModal) => {
            notifyModal.present();
        });
    }

    close() {
        this.onboardingService.close();
    }

    sendRecurrentFeedback(){
        this.recurrentSent = true;
        this.feedbackService
            .postOnboardingRecurrentFeedbackRequest(this.onboardingSelection.contacts, this.onboardingSelection.skills)
            .subscribe({
                next: () => {
                    this.close();
                },
                error: () => {
                    this.close();
                }
            });
    }
}
