import {ApiService} from './api.service';
import {BehaviorSubject, filter, first, Observable, tap} from 'rxjs';
import {Challenge, ChallengeStat, ChallengeStatus} from '../models/challenge';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Skill} from "../models/skill";

@Injectable({
    providedIn: 'root'
})
export class ChallengeService {

    challenges = new BehaviorSubject<Challenge[]>(null);
    challengeStats = new BehaviorSubject<ChallengeStat[]>(null);

    constructor(
        private readonly apiService: ApiService
    ) {
    }

    getChallenge(challengeId: number): Observable<Challenge> {
        return this.challenges.pipe(
            filter(challenges => !!challenges),
            first(),
            map(challenges => challenges.find(c => c.challenge_id === challengeId))
        );
    }

    refreshChallenges(): Observable<Challenge[]> {

        this.getChallengeStats().subscribe({
            next: stats => {
                this.challengeStats.next(stats);
            }
        });

        return this.apiService.get<{challenges: Challenge[]}>('/api/challenges')
            .pipe(map(response => response.challenges.map(c => new Challenge(c))))
            .pipe(tap( challenges => {
                this.challenges.next(challenges);
            }));
    }

    submitEventChallenge(data: {
        challenge_id: number,
        status: 'accepted' | 'declined' | 'performed' | 'failed',
        difficulty: boolean // true = hard, false = easy
    }) {
        return this.apiService.patch<any>('/api/challenges/answer/event', data);
    }

    submitQuestionChallenge(data: {
        challenge_id: number,
        score: number, // 0 => wrong, 100 => right
    }) {
        return this.apiService.patch<any>('/api/challenges/answer/question', data);
    }

    markAsAnswered(challenge: Challenge) {
        const newList = [];
        const challenges = this.challenges.value;
        for (const c of challenges) {
            if (c.challenge_id === challenge.challenge_id) {
                c.status = 'performed';
            } else {
                newList.push(c);
            }
        }

        this.challenges.next(newList);
    }

    getChallengeStats() {
        return this.apiService.get<{statistics: ChallengeStat[]}>('/api/challenges/statistics')
            .pipe(map(response => response.statistics.map(s => new ChallengeStat(s))));
    }
}
