import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '../../services/translate.service';
import {AuthService} from '../../services/auth.service';
import {AuthData} from '../../models/auth-status';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {ContactService} from '../../services/contact.service';
import {Contact, ContactListType} from '../../models/contact';
import {StringUtils} from "../../utils/string-utils";

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact.page.html',
    styleUrls: ['./add-contact.page.scss'],
})
export class AddContactPage implements OnInit {
    @Input() type: ContactListType;
    @Input() mode: 'update' | 'selection' = 'selection';
    @Input() contacts: Contact[] = [];
    public authData: AuthData;
    formGroup: FormGroup;

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly alertCtrl: AlertController,
        private readonly authService: AuthService,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.authData = this.authService.authStatus.value.authData;
        this.formGroup = new FormGroup({
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.pattern(StringUtils.EMAIL_REGEX)]),
            type: new FormControl({value: this.type, disabled: this.mode === 'update'}, [Validators.required]),
            title: new FormControl('', Validators.required),
        });
    }

    close(){
        this.modalCtrl.dismiss({added: false});
    }

    async addContact(){
        if (this.formGroup.invalid){
            return;
        }
        const data = this.formGroup.value;
        // Lowercase email
        data.email = data.email.toLowerCase();
        // Test if email is same as current user email
        if (data.email === this.authData.email){
            const alert = await this.alertCtrl.create({
                header: this.translateService.get(_('add-contact.ERREUR')),
                subHeader: this.translateService.get(_('add-contact.EMAIL_INVALIDE_2')),
                buttons: [this.translateService.get(_('add-contact.OK'))]
            });
            alert.present();
            return;
        }
        // Check if email doesn't already exist in contacts
        if (this.contacts.find(c => c.email === data.email)) {
            const alert = await this.alertCtrl.create({
                header: this.translateService.get(_('add-contact.ERREUR')),
                subHeader: this.translateService.get(_('add-contact.EMAIL_DEJA_UTILISE')),
                buttons: [this.translateService.get(_('add-contact.OK'))]
            });
            alert.present();
            return;
        }

        if (this.mode === 'update') {
            data.type = this.type;
        }

        data.source = 'local';
        data.checked = false;

        const contact = new Contact(data);

        this.modalCtrl.dismiss({added: true, contact});
    }
}
