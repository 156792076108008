<ion-content>
  <div class="container d-flex justify-content-start flex-column h-100">
    <div class="d-flex justify-content-end m-2">
      <div class="fs-2 text-green pt-1" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center p-relative h-100" *ngIf="swiperConfig">
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="sliderReady">
        <ion-icon name="chevron-back-outline" class="swiper-nav text-green fs-2 p-1" [class.enabled]="canSlideBack()" (click)="slideBack()"></ion-icon>
      </div>
      <swiper #slides [config]="swiperConfig">
        <ng-template swiperSlide>
          <div class="text-center">
            <img class="mb-2" src="assets/images/onboarding/onboarding-slide-1.svg" [style.width]="'200px'">
            <p>{{ 'onboarding-slides.SOFT_SKILLS'|translate }}</p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="text-center">
            <img class="mb-2" src="assets/images/onboarding/onboarding-slide-2.png" [style.width]="'200px'">
            <p>{{ 'onboarding-slides.PORTEFEUILLE'|translate }}</p>
          </div>
        </ng-template>
        <ng-template swiperSlide *ngIf="!authData.data.grades_criterion_active">
          <div class="text-center">
            <img class="mb-2" src="../../../../assets/images/onboarding/onboarding-slide-3.1.png" [style.width]="'200px'">
            <p>{{ 'onboarding-slides.EVALUE'|translate }}</p>
          </div>
        </ng-template>
        <ng-template swiperSlide *ngIf="authData.data.grades_criterion_active">
          <div class="text-center">
            <img class="mb-2" src="../../../../assets/images/onboarding/onboarding-slide-3.2.png" [style.width]="'200px'">
            <p>{{ 'onboarding-slides.EVALUE_EDU'|translate }}</p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="text-center">
            <img class="mb-2" src="../../../../assets/images/onboarding/onboarding-slide-4.png" [style.width]="'200px'">
            <p>{{ 'onboarding-slides.NOTIFICATIONS'|translate }}</p>
            <button class="btn btn-green btn-rounded btn-block mt-2" (click)="authorize()">
                {{ 'onboarding-slides.BUTTON_ACCES_NOTIFS' | translate }}
            </button>
          </div>
        </ng-template>
      </swiper>
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="sliderReady">
        <ion-icon name="chevron-forward-outline" class="swiper-nav text-green fs-2 p-1" [class.enabled]="canSlideForward()" (click)="slideForward()"></ion-icon>
      </div>
    </div>
    <div class="p-relative py-2">
      <div class="swiper-pagination green" #slidePagination>
        <div class="swiper-pagination-bullet" *ngFor="let slide of [1,2,3,4]"></div>
      </div>
    </div>
  </div>
</ion-content>
