
export const ChallengeMultiChoiceValidator = (controls: any) => {
    const values: any = Object.values(controls.getRawValue());

    // We must have at least one true statement
    for (const value of values) {
        if (value === true) {
            return null;
        }
    }
    return { requiredOneMin: true };
};
