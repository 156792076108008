<div class="container" [formGroup]="formGroup" *ngIf="formGroup">
  <h3>{{ 'add-contact.TITRE_EVALUATEUR' | translate }}</h3>

  <!-- Firstname -->
  <div class="form-group pt-2 pb-1">
    <label>{{ 'add-contact.TITRE' | translate }}</label>
    <input type="text" class="form-control" formControlName="title">
  </div>

  <div class="d-flex gap-2 mt-4">
    <button class="btn btn-block btn-outlined btn-green btn-rounded" (click)="close()">{{ 'CANCEL'|translate }}</button>
    <button class="btn btn-block btn-primary btn-green btn-rounded"  [disabled]="formGroup.invalid" (click)="addContact()">{{ 'OK'|translate }}</button>
  </div>
</div>
