import {Component, OnInit} from '@angular/core';
import {AuthData} from '../../models/auth-status';
import {AuthService} from '../../services/auth.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
    selector: 'app-footer-toolbar',
    templateUrl: './footer-toolbar.component.html',
    styleUrls: ['./footer-toolbar.component.scss'],
})
export class FooterToolbarComponent implements OnInit {
    /* home, challenge, feedback, badge, profile */
    tabs = [
        { icon: 'home', label: _('footer.HOME'), path: '/home' },
        { icon: 'challenge', label: _('footer.CHALLENGE'), path: '/challenges', condition: (authData: AuthData) => {
                return authData.data.challenges_active || false;
            }},
        { icon: 'feedback', label: _('footer.FEEDBACK'), path: '/feedbacks' },
        { icon: 'badge', label: _('footer.BADGE'), path: '/badge' },
        { icon: 'user', label: _('footer.PROFILE'), path: '/profile' }
    ];
    displayedTab: any[];

    constructor(
        private readonly authService: AuthService,
    ) { }

    ngOnInit() {
        this.displayedTab = this.tabs.filter(tab =>
            tab.condition ? tab.condition(this.authService.authStatus.value.authData) : true
        );
    }
}
