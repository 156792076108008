/* tslint:disable:variable-name */
import {User} from './user';
import {FeedbackReceived, FeedbackAnswered, FeedbackSent, Feedback} from './feedback';
import {Gamification} from './gamification';
import {Skill, SkillPath} from './skill';
import {LearningPeriod} from './learning-period';
import {Wallet} from './wallet';
import {Grid} from "./grid";
import {Challenge, ChallengeStatus, ChallengeType} from "./challenge";

export class RequestedFeedback {
    skill: number;
    recipients: {
        email: string;
        date: string;
    }[];
}

export class SessionData {
    alreadyRequested: RequestedFeedback[];
    school_logo: string;
    feedbacks?: {
        received?: FeedbackReceived[]; // je suis évaluateur
        sent?: FeedbackSent[]; // je suis évalué (étudiant)
        auto?: Feedback[];
        unread_answers?: number;
        unread_answers_by_skill?: { skill: number }[];
        answered?: FeedbackAnswered[];
        recurrent?: { skillId: number,  }[];
    };
    company?: {
        rating_icon?: string;
        [key: string]: any; // Wildcard properties
    };
    requestedSkill: number[]; // array of skill ids
    user?: User;
    groupingService?: any;
    gamification?: Gamification; // Stats => source of quality index
    image?: string;
    uncompletedGrids?: Grid[];
    challenges_active: boolean; // User has access to challenges
    challenges: Challenge[];
    grades_criterion_active: boolean; // Grades given by schools (and feedbacks)
    students_with_wallet_percent: number;
    students_with_5_badges_percent: number;
    skillsPaths: SkillPath[];
    learningPeriod: LearningPeriod;
    events: Skill[];
    skillsLimit: number;
    [key: string]: any; // Wildcard properties

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.feedbacks.received = (obj?.feedbacks?.received || []).map(f => new FeedbackReceived(f));
            this.feedbacks.sent = (obj?.feedbacks?.sent || []).map(f => new FeedbackSent(f));
            this.feedbacks.answered = (obj?.feedbacks?.answered || []).map(f => new FeedbackAnswered(f));
            this.feedbacks.unread_answers_by_skill = (obj?.feedbacks?.unread_answers_by_skill || []).map(f => new FeedbackAnswered(f));
            this.uncompletedGrids = (obj?.uncompletedGrids || []).map(g => new Grid(g));
            this.gamification = new Gamification(obj.gamification);
            this.skillsPaths = (obj?.skillsPaths || []).map(s => new SkillPath(s));
            this.learningPeriod = new LearningPeriod(obj.learningPeriod);
            this.events = (obj?.events || []).map(s => new Skill(s));
            this.user = new User(obj.user);
            this.challenges = (obj?.challenges || []).map(c => new Challenge(c));

            // For all gamification stats
            this.gamification.myskills.statistics.all.forEach(s => {
                // Add request count
                s.requests = this.feedbacks.sent.filter(f => f.skill.id === s.skill.id).length;
                // Add unread answers count
                s.unread_answers = this.feedbacks.unread_answers_by_skill.filter(u => u.skill === s.skill.id)?.length || 0;
            });
        }
    }

    getWallet(id: number): Wallet {
        return this.gamification.wallets.find(w => w.id === id);
    }

    alreadyRequestedSkill(skillId: number) {
        return this.alreadyRequested.find(s => s.skill === skillId) !== undefined;
    }

    getChallenges(status: ChallengeStatus): Challenge[] {
        return (this.challenges || []).filter(c => c.status === status);
    }
}

export class AuthData {
    userId: string;
    email: string;
    access_token: string;
    data: SessionData;
    suggestions = [];
    history = [];

    constructor(userId: string, email: string, access_token: string) {
        this.userId = userId;
        this.email = email;
        this.access_token = access_token;
        this.data = new SessionData();
    }
}

export class AuthStatus {
    constructor(
        public readonly authData: AuthData,
        public readonly logged: boolean = true,
        public readonly authDone: boolean = true,
    ) {
    }
}
