import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NavController} from '@ionic/angular';
import {UpdateService} from '../services/update.service';
import {filter, Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      private readonly authService: AuthService,
      private readonly navCtrl: NavController,
      private readonly updateService: UpdateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authStatus.pipe(
        // Make sure the auth is done
        filter(status => status?.authDone),
        // Make sure the user is logged
        map(status => status.logged),
        // Redirect to login if not logged
        tap(logged => {
          if (!logged) {
            this.navCtrl.navigateRoot('/login');
          }
        })
    );
  }
}
