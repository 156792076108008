import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TooltipModalComponent} from '../../modals/tooltip-modal/tooltip-modal.component';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent  implements OnInit {

  @Input() title: string;
  @Input() text: string;
  @Input() icon: string;

  constructor(
      private readonly modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  async openTooltipModal() {
    const modal = await this.modalCtrl.create({
      component: TooltipModalComponent,
      componentProps: {
        title: this.title || 'Aucun titre',
        text: this.text || 'Aucun texte n\'a été défini pour ce tooltip',
        icon: this.icon,
      },
      cssClass: 'modal-auto-height'
    });
    modal.present();
  }
}
