<div class="container">
  <div class="d-flex justify-content-center">
    <img [src]="icon ? icon : 'assets/images/home-1.svg'">
  </div>
  <h5 class="fw-500 fs-4" [innerHTML]="title"></h5>
  <p [innerHTML]="text" class="text-blue-dark fs-4 py-2"></p>
  <button class="btn btn-green btn-block btn-rounded btn-outlined clickable"
          (click)="close()"
  >
    {{ 'CLOSE' | translate }}
  </button>
</div>
