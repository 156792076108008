import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/capacitor';
import { init as sentryAngularInit } from '@sentry/angular';

if (environment.production) {
    enableProdMode();
    console.log = () => {};
}

// Init sentry
Sentry.init({
        dsn: 'https://9ca5d621b76d52fa0f53a144e4a9639c@o4504710279528448.ingest.sentry.io/4506001518886912',
        enabled: environment.production,
    },
    sentryAngularInit
);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
