<ion-content>
  <div class="container">
    <div class="d-flex justify-content-between mb-2">
      <h1>{{ 'onboarding-skills.CHOISIR_PARCOURS' | translate }}</h1>
    </div>

    <div class="card d-flex justify-content-between gap-2 p-2 mb-2 clickable" (click)="selectPath(path)" *ngFor="let path of skillPaths">
      <h3>{{ path.label }}</h3>
      <div>
        <ion-icon name="checkmark-circle" class="fs-2 text-green" *ngIf="path.id === selectedPath"></ion-icon>
        <ion-icon name="radio-button-off" class="fs-2 text-blue-dark" *ngIf="path.id !== selectedPath"></ion-icon>
      </div>
    </div>

    <button class="btn btn-green btn-block btn-rounded" [disabled]="selectedPath === null" (click)="submit()">{{ 'SAVE' | translate }}</button>

  </div>
</ion-content>
