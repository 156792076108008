<ion-content>
  <div class="container p-relative d-flex justify-content-start flex-column h-100 p-2">
    <div class="p-absolute top-0 right-0">
      <div class="fs-2 text-green p-1" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
      <img src="assets/images/onboarding/onboarding-challenges-1.png" [style.width]="'150px'">
      <p class="pt-2" [innerHTML]="'onboarding-challenges.DEFIS'|translate"></p>
    </div>
  </div>
</ion-content>
