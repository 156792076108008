<ion-app>
    <div id="app-container">
        <div id="left-menu" *ngIf="authData && canShowMenu">
            <app-left-menu></app-left-menu>
        </div>
        <div id="router-wrapper">
            <div id="router-container">
                <ion-router-outlet id="menuContent" id="menu-content"></ion-router-outlet>
            </div>
            <ion-footer id="main-footer" *ngIf="authData && canShowMenu">
                <ion-toolbar>
                    <app-footer-toolbar></app-footer-toolbar>
                </ion-toolbar>
            </ion-footer>
        </div>
    </div>
</ion-app>

