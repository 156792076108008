const API_ENDPOINT = 'https://manager.globalcoss.com';

export const environment = {
    production: true,
    apiEndpoint: API_ENDPOINT,
    apiLoginEndpoint: API_ENDPOINT + '/oauth/v2/token',
    imageEndpoint: API_ENDPOINT + '/images/',
    version: '2.4.0',
    buildNumber: 20400,
    debugTranslations: false,
};

