<div class="container" *ngIf="displayedTab">
  <img src="assets/images/logo-color.png">
  <div class="menu">
    <div class="menu-item" *ngFor="let tab of displayedTab">
      <a [routerLink]="tab.path"
         routerLinkActive="active"
      >
        <span class="icon coss-{{ tab.icon }}"></span>
        <span>{{ tab.label|translate }}</span>
      </a>
    </div>
  </div>
</div>
