import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

// Collapse animation with angular animations
const collapseAnimation = [
  trigger('collapse', [
    state('open', style({opacity: 1, 'max-height': '*', transform: 'translateY(0)'})),
    state('closed', style({opacity: 0, 'max-height': 0, transform: 'translateY(-100%)'})),
    transition('open <=> closed', [
      animate('0.3s ease-in-out')
    ])
  ])
];

@Component({
  selector: 'app-card-collapse',
  templateUrl: './card-collapse.component.html',
  styleUrls: ['./card-collapse.component.scss'],
  animations: [collapseAnimation]
})
export class CardCollapseComponent  implements OnInit {
  opened = true;
  @Input() title: string;
  @Input() shadowSize: 'shadow-sm'|'shadow-lg' = 'shadow-lg';
  @Input() paddingSize = 'p-2';
  @Input() asCard = true;
  @Input() titleClass: string = null;


  constructor() { }

  ngOnInit() {}

  toggleContent() {
    this.opened = !this.opened;
  }
}
