import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertController, NavController, ToastController} from '@ionic/angular';
import {marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {Feedback, FeedbackAnswer, FeedbackReceived, FeedbackStatus} from '../../models/feedback';
import {FeedbackService} from '../../services/feedback.service';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '../../services/translate.service';
import {OnboardingService} from '../../services/onboarding.service';
import {FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {StringUtils} from '../../utils/string-utils';
import {LoadingHelper} from "../../utils/loading.helper";

@Component({
  selector: 'app-fb-answer',
  templateUrl: './fb-answer.component.html',
  styleUrls: ['./fb-answer.component.scss'],
})
export class FbAnswerComponent implements OnInit {

  @Input() type: 'onboarding' | 'guest' | 'feedback' = 'feedback';
  @Input() user: { firstname: string };
  @Input() hasRecommendation = true;
  @Input() recipient: {
    firstname: string
    lastname: string
    email: string
  };
  @Input() feedbacks: Feedback[];
  @Output() feedbacksAnswered = new EventEmitter<void>();
  public selfEvaluation = false;
  public feedback: Feedback;
  public tagItems: { label: string; value: string }[];
  public weaknessItems: { label: string; value: string }[];
  public strengthItems: { label: string; value: string }[];
  public selectedStrengthTags: string[] = [];
  public selectedWeaknessTags: string[] = [];
  public coffeeRequested = false;
  public isGuest = false;
  public answerForm: FormGroup;
  public recommendation: string;

  constructor(
      private readonly navCtrl: NavController,
      private readonly alertCtrl: AlertController,
      private readonly feedbackService: FeedbackService,
      private readonly authService: AuthService,
      private readonly translateService: TranslateService,
      private readonly toastCtrl: ToastController,
      private readonly onboardingService: OnboardingService,
      public formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {

    if (this.type === 'guest'){
      this.isGuest = true;
    }

    this.answerForm = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(1)]],
      lastname: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.pattern(StringUtils.EMAIL_REGEX)]],
    });

    const feedback = this.feedbacks[0];
    this.selfEvaluation = feedback.self_evaluation;
    this.selectFeedback(feedback);

    this.answerForm.controls.firstname.setValue(this.recipient?.firstname || '');
    this.answerForm.controls.lastname.setValue(this.recipient?.lastname || '');
    this.answerForm.controls.email.setValue(this.recipient?.email || '');
  }

  async ignoreFeedback(){
    const prompt = await this.alertCtrl.create({
      header: this.translateService.get(_('rating.TITLE_1')),
      message: this.translateService.get(_('rating.MESSAGE_1')),
      buttons: [
        {
          text: this.translateService.get(_('rating.BUTTON_1')),
          handler: d => console.log('Cancel clicked')
        },
        {
          text: this.translateService.get(_('rating.BUTTON_2')),
          handler: d => {
            this.updateFeedbackStatus(1);
            // Ask user permission
            this.feedbackService.ignoreFeedback(this.feedback, this.isGuest, this.translateService.getLocale()).subscribe((data: any) => {
                // Select next feedback or emit event if no more feedbacks
                const nextFeedback = this.getNextPendingFeedback(this.feedback.id);
                if (nextFeedback) {
                    this.selectFeedback(nextFeedback);
                } else {
                    this.feedbacksAnswered.emit();
                }
            });
          }
        }
      ]
    });
    await prompt.present();
  }

  navigateBack() {
    if (this.type !== 'onboarding') {
      this.navCtrl.back();
    }
  }

  selectFeedback(feedback: Feedback) {
    // Reset question ratings
    feedback.skill.questions.forEach(q => q.score = 0);
    // Reset coffee request
    this.coffeeRequested = false;
    // Init tag list
    this.initTagList(feedback);
    // Filter out feedbacks if status is not pending
    this.feedbacks = this.feedbacks.filter(f => f.status === 0);
    // Select feedback
    this.feedback = feedback;
  }

  async sendFeedback(): Promise<void> {
    let error: string = null;
    let message: string = null;
    let canContinue = true;

    if (this.isGuest) {
      // Check if all fields are filled
      if (!this.answerForm.valid) {
        const prompt = await this.alertCtrl.create({
          header: this.translateService.get(_('guest.REPONSE_INCOMPLETE')),
          message: this.translateService.get(_('guest.SAISIE_COORDONNEES')),
          buttons: [
            {
              text: this.translateService.get(_('OK')),
              handler: data => console.log('OK clicked')
            }
          ]
        });
        prompt.present();
        return;
      } else {
        this.recipient.firstname = this.answerForm.controls.firstname.value;
        this.recipient.lastname = this.answerForm.controls.lastname.value;
        this.recipient.email = this.answerForm.controls.email.value;
      }
    }

    const questions = this.feedback.skill.questions;
    // Check if at least one strength has been selected
    if (this.tagItems?.length > 0 && this.selectedStrengthTags.length === 0) {
      error = this.translateService.get(_('rating.ATTENTION_POINT_FORT'));
      canContinue = false;
    }

    // Check if all questions have been answered
    else if (questions.filter(q => q.score === 0).length > 0) {
      error = this.translateService.get(_('rating.ATTENTION_QUESTION'));
      canContinue = false;
    }
    // Check if all questions have no opinion
    else if (questions.filter(q => q.score === -1).length === questions.length) {
      error = this.translateService.get(_('rating.ATTENTION_SANS_OPINION'));
      canContinue = false;
    }
    // Check if all questions have a rating of 1
    else if (questions.filter(q => q.score === 1).length === questions.length) {
      error = this.translateService.get(_('rating.ATTENTION_1_ETOILE'));
      message = this.translateService.get(_('rating.CONFIRMER'));
    }
    // Check if all questions have a rating of 5
    else if (questions.filter(q => q.score === 5).length === questions.length) {
      error = this.translateService.get(_('rating.ATTENTION_5_ETOILE'));
      message = this.translateService.get(_('rating.CONFIRMER'));
    }

    if (error) {
      const buttons = [
        {text: this.translateService.get(_('rating.MODIFIER')), handler: () => {}},
      ];
      if (canContinue) {
        buttons.push({text: 'OK', handler: () => this.postFeedback()});
      }
      const options: any = {header: error, buttons};
      if (message) {
        options.message = message;
      }
      const prompt = await this.alertCtrl.create(options);
      prompt.present();
      return;
    }

    this.postFeedback();
  }

  private getNextPendingFeedback(id?: number): Feedback {
    return this.feedbacks.find(f => f.id !== id && f.status === 0);
  }

  private async postFeedback(){
    await LoadingHelper.open();

    // Remove duplicate tags
    const weaknesses = this.selectedWeaknessTags.filter((item, index) => this.selectedWeaknessTags.indexOf(item) === index);
    const strengths = this.selectedStrengthTags.filter((item, index) => this.selectedStrengthTags.indexOf(item) === index);

    // Post feedback
    const answer: FeedbackAnswer = {
      id: this.feedback.id,
      strengths,
      weaknesses,
      coffee_request: this.coffeeRequested && !this.selfEvaluation ? 1 : 0,
      answer: {questions: this.feedback.skill.questions.map(q => ({id: q.id, score: q.score}))},
      recommendation: this.recommendation,
    };
    this.feedbackService
        .postFeedbackAnswer(answer, this.isGuest, this.recipient, this.translateService.getLocale())
        .subscribe(data => {
          // Empty recommendation
          this.recommendation = '';
          // Update feedbacks
          this.updateFeedbackStatus(1);
          // Display toast
          this.toastCtrl.create({
            message: this.translateService.get(_('rating.FEEDBACK_ENVOYE')),
            duration: 2000
          }).then(toast => toast.present());

          // Select next feedback or emit event if no more feedbacks
          const nextFeedback = this.getNextPendingFeedback(this.feedback.id);
          if (nextFeedback) {
            this.selectFeedback(nextFeedback);
            LoadingHelper.close();
          } else {
            this.feedbacksAnswered.emit();
          }
        });
  }

  private initTagList(feedback: Feedback): void {
    // Reset tag selection
    this.selectedStrengthTags = [];
    this.selectedWeaknessTags = [];
    // Set tag list
    this.tagItems = feedback.skill.tags
        .filter(t => t !== '')
        .map(t => ({label: t, value: t}));
    this.weaknessItems = this.tagItems;
    this.strengthItems = this.tagItems;
  }

  removeTags(type: 'weaknesses'|'strengths', tags: string[]) {
    let tagItems = this.tagItems;
    tagItems = tagItems.filter(t => !tags.includes(t.value));
    if (type === 'weaknesses') {
      this.strengthItems = tagItems;
    }
    if (type === 'strengths') {
      this.weaknessItems = tagItems;
    }
  }

  nextOnboardingStep() {
    this.feedbacksAnswered.emit();
  }

  /**
   * Updates the status of the current feedback and synchronizes it with the corresponding entry in the feedbacks list.
   */
  private updateFeedbackStatus(status: FeedbackStatus): void {
    this.feedback.status = status;
    const feedback = (this.feedbacks || []).find(f => f.id === this.feedback.id);
    if (feedback) {
      feedback.status = status;
    }
  }
}

