/* tslint:disable:variable-name */
import {Skill} from './skill';

/**
 * Feedback status
 * 0: Pending => send to evaluator, waiting for answer
 * 1: Answered => evaluator answered
 * 2: Ignored => evaluator ignored
 * 3: User deleted => user deleted the feedback
 */
export type FeedbackStatus = 0 | 1 | 2 | 3;

/**
 * Coffee value
 * Evaluator fait la demande de café (ACCEPTED BY DEFAULT)
 * 0: Did not ask for coffee
 * 1: Asked for coffee
 * 2: Coffee request was refused
 */
export type CoffeeAskedStatus = 0 | 1 | 2;


export class Rating {
    score: number;
}

export class FeedbackSent {
    answeredAt: string;
    category: number; // Category id
    coffee?: CoffeeAskedStatus;
    feedback: number; // Feedback id
    recipientEmail: string;
    recipientFirstname: string;
    recipientLastname: string;
    skill: {id: number};
    status: FeedbackStatus;
    title: string;
    eventTitle: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export type Recipient = {
    firstname: string;
    lastname: string;
    email: string;
};

export type FeedbackAnswer = {
    id: number;
    strengths?: string[];
    weaknesses?: string[];
    coffee_request?: 0 | 1 ; // 0: Did not ask for coffee, 1: Asked for coffee
    answer: {questions: {id: number, score: number}[]};
    firstname?: string;
    lastname?: string;
    email?: string;
    recommendation?: string;
};

export class Feedback {
    created_at: string;
    id: number;
    ratings: Rating[];
    self_evaluation: boolean;
    skill: Skill;
    status: FeedbackStatus;
    strengths_translated: string[];
    weaknesses_translated: string[];
    coffee: CoffeeAskedStatus;
    coffee_request?: 0 | 1 ; // 0: Did not ask for coffee, 1: Asked for coffee

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.skill = obj?.skill ? new Skill(obj.skill) : null;
        }
    }
}

export class FeedbackReceived {
    coffee: CoffeeAskedStatus;
    firstname: string;
    lastname: string;
    name: string;
    feedbacks: Feedback[];
    recommendations_active: boolean;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.feedbacks = (obj?.feedbacks || []).map(f => new Feedback(f));
            this.recommendations_active = true;
        }
    }
}

export class FeedbackAnswered {
    skill: {id: number};

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PostOnboardingFeedbacks {
    recipients: {
        firstname: string;
        lastname: string;
        email: string;
        roles: string[];
        type: string;
        title: string
    }[];
    eventTitle: string;
    skills: number[];
}
