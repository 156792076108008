/* tslint:disable:variable-name */

import {User} from './user';

export class Company {
    name: string;
    automation_active: boolean;
    badges_active: boolean;
    is_domoscio_linked: boolean;
    exclude_dataviz: boolean;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class School {

    automation_active: boolean;
    badge_logo: 'coss'|'istec'|'wsf'|'isg'|'walt';
    guest_weight: string;
    name: string;
    pro_weight: string;
    quality_all_feedback_count_a: number;
    quality_all_feedback_count_b: number;
    quality_pro_feedback_count_a: number;
    quality_pro_feedback_count_b: number;
    quality_teacher_feedback_count_a: number;
    quality_teacher_feedback_count_b: number;
    quality_tutor_feedback_count_a: number;
    quality_mad_all_feedback_count_a: number;
    quality_mad_associative_feedback_count_b: number;
    saml_entity: string;
    teacher_weight: string;
    can_delete_account: boolean;
    recommendations_active: boolean;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

}

export class SchoolPath {
    challenges_active: boolean;
    duration: number;
    grades_criterion_active: boolean;
    level: number;
    name: string;
    school: School;
    enable_select_skill_path: boolean;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

}

export class LearningPeriod {
    company: Company;
    end_date: string;
    learning_type: string;
    school_path: SchoolPath;
    school_referent: User;
    start_date: string;
    student: User;
    tutor: User;
    year: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.company = new Company(obj.company);
            this.school_path = new SchoolPath(obj.school_path);
            this.school_referent = new User(obj.school_referent);
            this.student = new User(obj.student);
            this.tutor = new User(obj.tutor);
        }
    }


}
