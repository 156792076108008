/* tslint:disable */
import {LearningPeriod} from "./learning-period";

export class Grid {
    id: number;
    base_grid: BaseGrid;
    learning_period: LearningPeriod;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class BaseGrid {
    title: string;
    base_grid_questions: BaseGridQuestions[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class BaseGridQuestions {
    header: string;
    title: string;
    type: string;
    freeAnswer: string;
    selectedAnswer: number;
    selectedComment: string;
    base_grid_answers: BaseGridAnswers[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class BaseGridAnswers {
    id: number;
    title: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
