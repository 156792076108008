import { Component } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {OnboardingService} from '../../../services/onboarding.service';
import {StorageService} from '../../../services/storage.service';
import {Feedback} from '../../../models/feedback';
import {AuthData} from '../../../models/auth-status';
import {LoadingHelper} from '../../../utils/loading.helper';

@Component({
    selector: 'app-onboarding-self-evaluation',
    templateUrl: './onboarding-self-evaluation.page.html',
    styleUrls: ['./onboarding-self-evaluation.page.scss'],
})
export class OnboardingSelfEvaluationPage {

    public feedbacks: Feedback[] = null;
    public init: boolean;
    private authData: AuthData;

    constructor(
        private readonly authService: AuthService,
        private readonly storageService: StorageService,
        private readonly onboardingService: OnboardingService,
    ) {
    }

    ionViewWillEnter() {
        this.authService.authStatus.subscribe({
            next: status => {
                if (status.authDone && !this.init) {
                    this.init = true;
                    this.authData = status.authData;
                    // Get pending self evaluation feedback
                    this.feedbacks = status.authData.data.feedbacks.auto.filter(f => f.status === 0);
                }
            }
        });
    }

    async onFeedbacksAnswered() {
        // Check if skills where selected
        const res = await this.storageService.get('onboarding-selection');
        const skills = res?.skills || [];

        // Dismiss loader
        await LoadingHelper.close();

        // Has feedback been selected ?
        if (skills.length > 0) {
            this.onboardingService.goTo('contacts', true);
        } else {
            this.onboardingService.goTo('finished', true);
        }
    }
}
