import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ContactListType} from '../../models/contact';
import {TranslateService} from '../../services/translate.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-no-contact-modal',
  templateUrl: './no-contact-modal.component.html',
  styleUrls: ['./no-contact-modal.component.scss'],
})
export class NoContactModalComponent  implements OnInit {
  listName: string;
  listLength: number;

  @Input() set types(listTypes: ContactListType[]) {
    const listNames: string[] = [];
    this.listLength = listTypes.length;
    for (const listType of listTypes) {
      listNames.push(`"${this.translateService.getContactListName(listType)}"`);
    }

    // Create list coma separated, except for the last one that will be separated by 'ou'
    let listName = '';
    if (listNames.length === 1) {
      listName = listNames[0];
    } else {
      listName = listNames.slice(0, -1).join(', ');
      listName += ' ' + this.translateService.get(_('OR')) + ' ' + listNames.slice(-1);
    }
    this.listName = listName;
  }

  constructor(
      private readonly modalCtrl: ModalController,
      private readonly translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  close(yes: boolean) {
    this.modalCtrl.dismiss({ yes });
  }
}
