import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {map, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ApiFormat} from '../../enums/api-format';
import {AuthData, AuthStatus, SessionData} from '../models/auth-status';
import {StorageService} from './storage.service';
import {UpdateService} from './update.service';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from "./translate.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    triggerLogin = new BehaviorSubject<{email: string, password: string}>(null);

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly translateService: TranslateService,
        private readonly updateService: UpdateService
    ) { }

    public saveUser(d: SessionData): AuthData {
        const data = new SessionData(d);

        // Save the user data
        const status = new AuthStatus(new AuthData(data.user.id, data.user.email, this.apiService.getToken()), true, true);
        status.authData.data = data;

        // Enable menu
        this.updateService.updateStatus.next(true);

        this.authService.authStatus.next(status);
        return status?.authData;
    }

    public updateUserInfo() {
        return this.apiService
            .get<SessionData>('/api/user')
            .pipe(map(data => {
                    // Save locale
                    this.translateService.changeLocale(data.user.locale);
                    return this.saveUser(data);
                })
            );
    }

    public patchUser(info: any){
        return this.apiService
            .patch<SessionData>('/api/user', info)
            .pipe(map(response => {
                    return this.saveUser(response).data;
                }),
            );
    }

    public deleteAccount(){
        return this.apiService
            .delete<any>('/api/user')
            .pipe(map(response => true),
            );
    }

    public savePlatformUsed(isBrowser: boolean) {
        const platform = isBrowser ? 'browser' : 'mobile';
        return this.apiService
            .post('/api/platform/used', {platform}, ApiFormat.FORM)
            .pipe(map(response => true));
    }

    public resetNewFeedbackCount() {
        return this.apiService.patch('/api/update/feedback/count', {})
            .pipe(tap(r => {
                const status = this.authService.authStatus.value;
                if (status && status.authData) {
                    status.authData.data.user.new_feedback_count = 0;
                }
            }));
    }

    public resetNewBadgeCount() {
        const status = this.authService.authStatus.value;
        status.authData.data.user.new_badge_count = 0;
        return this.apiService.patch('/api/update/badge/count', {})
            .pipe(tap(r => {
                if (status && status.authData) {
                    status.authData.data.user.new_badge_count = 0;
                }
            }));
    }

    changeUserLocale(locale: string) {
        return this.patchUser({locale});
    }
}
