import {Component, ElementRef, ViewChild} from '@angular/core';
import { NavController } from '@ionic/angular';
import {AuthService} from '../../../services/auth.service';
import {OnboardingService} from '../../../services/onboarding.service';
import {SwiperOptions} from 'swiper/types';
import {SwiperComponent} from 'swiper/angular';

@Component({
    selector: 'app-onboarding-answer',
    templateUrl: './onboarding-answer.page.html',
    styleUrls: ['./onboarding-answer.page.scss'],
})
export class OnboardingAnswerPage {
    @ViewChild('slides', {static: false}) slides: SwiperComponent;
    @ViewChild('slidePagination', {static: false}) slidePagination: ElementRef;
    swiperConfig: SwiperOptions;

    constructor(
        private readonly navCtrl: NavController,
        private readonly authService: AuthService,
        private readonly onboardingService: OnboardingService,
    ) { }

    ionViewWillEnter() {
        this.swiperConfig = {
            pagination: {
                el: this.slidePagination.nativeElement,
            }
        };
    }

    moveToNext(slides){
        slides.slideNext();
    }

    close(){
        this.authService.authStatus.value.authData.data.user.is_onboarding_answer_finished = true;
        this.onboardingService.onboardingFinished('answer').subscribe();
        this.onboardingService.close();
    }

    canSlideForward(): boolean {
        return !this.slides.swiperRef.isEnd;
    }

    slideForward() {
        this.slides.swiperRef.slideNext();
    }

    canSlideBack() {
        return !this.slides.swiperRef.isBeginning;
    }

    slideBack() {
        this.slides.swiperRef.slidePrev();
    }
}
