import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AppUpdateService {

    constructor(
        private readonly apiService: ApiService,
        private readonly platform: Platform,
    ) { }

    static semverToNumber(a: string): number {
        // Convert string 1.2.3 to number 1002003
        const versionNumber = a.split('.');
        let version = 0;
        for (let i = 0; i < versionNumber.length; i++) {
            version += Number(versionNumber[i]) * Math.pow(1000, versionNumber.length - i);
        }

        return version;
    }

    public checkUpdates(currentVersion: string): Observable<'update' | 'allowed'> {
        return this.apiService
            .get<{ iosMinVersion: string, androidMinVersion: string }>('/api/check-update')
            .pipe(map(res => {

                let minVersion = null;
                // Check platform
                if (this.platform.is('ios')) {
                    minVersion = res.iosMinVersion;
                } else {
                    minVersion = res.androidMinVersion;
                }

                // Compare currentVersion and res.minVersion (semantic versioning)
                const minVersionNumber = AppUpdateService.semverToNumber(minVersion);
                const versionNumber = AppUpdateService.semverToNumber(currentVersion);

                if (versionNumber < minVersionNumber) {
                    return 'update';
                }

                return 'allowed';
            }));
    }
}
