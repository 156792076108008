import {NgModule} from '@angular/core';
import {OnboardingSkillsPage} from './onboarding-skills/onboarding-skills.page';
import {OnboardingSlidesPage} from './onboarding-slides/onboarding-slides.page';
import {OnboardingDashboardPage} from './onboarding-dashboard/onboarding-dashboard.page';
import {OnboardingBlankPage} from './onboarding-blank/onboarding-blank.page';
import {OnboardingContactsPage} from './onboarding-contacts/onboarding-contacts.page';
import {OnboardingChallengesComponent} from './onboarding-challenges/onboarding-challenges.component';
import {OnboardingAnswerPage} from './onboarding-answer/onboarding-answer.page';
import {OnboardingSelfEvaluationPage} from './onboarding-self-evaluation/onboarding-self-evaluation.page';
import {OnboardingTermsPage} from './onboarding-terms/onboarding-terms.page';
import {OnboardingFinishedPage} from './onboarding-finished/onboarding-finished.page';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SwiperModule} from 'swiper/angular';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared.module';
import {FormsModule} from '@angular/forms';
import {
    FeedbackPickEvaluatorPageModule
} from '../../pages/feedbacks/feedback-pick-evaluator/feedback-pick-evaluator.module';
import {OnboardingPickCourseComponent} from "./onboarding-pick-course/onboarding-pick-course.component";
import {RouterLink} from "@angular/router";

@NgModule({
    declarations: [
        OnboardingSkillsPage,
        OnboardingSlidesPage,
        OnboardingDashboardPage,
        OnboardingBlankPage,
        OnboardingContactsPage,
        OnboardingChallengesComponent,
        OnboardingAnswerPage,
        OnboardingSelfEvaluationPage,
        OnboardingTermsPage,
        OnboardingFinishedPage,
        OnboardingPickCourseComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        SwiperModule,
        SharedModule,
        FormsModule,
        FeedbackPickEvaluatorPageModule,
        RouterLink
    ],
    exports: [
        OnboardingSkillsPage,
        OnboardingSlidesPage,
        OnboardingDashboardPage,
        OnboardingBlankPage,
        OnboardingContactsPage,
        OnboardingChallengesComponent,
        OnboardingAnswerPage,
        OnboardingSelfEvaluationPage,
        OnboardingTermsPage,
        OnboardingFinishedPage,
        OnboardingPickCourseComponent
    ],
})
export class OnboardingModule {
}
