import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';


@Component({
	selector: 'app-onboarding-blank',
	templateUrl: './onboarding-blank.page.html',
	styleUrls: ['./onboarding-blank.page.scss'],
})
export class OnboardingBlankPage implements OnInit {

	public selectedFeedback = null;
	public selectedSkills = null;
	constructor(
		public navCtrl: NavController,
		private route: ActivatedRoute,
		private router: Router) {
		this.route.queryParams.subscribe(params => {
			if (this.router.getCurrentNavigation().extras.state) {
				this.selectedFeedback = this.router.getCurrentNavigation().extras.state.selectedSkills;
				this.selectedSkills = this.router.getCurrentNavigation().extras.state.selectedAllSkills;

				let navigationExtras: NavigationExtras = {
					state: {
						selectedAllSkills: this.selectedSkills,
						selectedSkills: this.selectedFeedback
					}
				};
				this.navCtrl.navigateForward(["onboarding-self-evaluation"], navigationExtras);
			}
		});
	}

	ngOnInit() {
	}

}
