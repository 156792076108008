import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {AddContactPage} from '../../modals/add-contact/add-contact.page';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Contact, ContactListType, ContactType} from '../../models/contact';
import {ContactService} from '../../services/contact.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {AddContactTitleComponent} from '../../modals/add-contact-title/add-contact-title.component';
import {AuthService} from '../../services/auth.service';
import {Skill} from '../../models/skill';
import {SkillStatistics} from '../../models/gamification';
import {AuthData} from '../../models/auth-status';
import {NoContactModalComponent} from '../../modals/no-contact-modal/no-contact-modal.component';
import {ContactHelper} from '../../utils/contact.helper';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ContactListComponent,
    multi: true,
  }]
})
export class ContactListComponent  implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() type: ContactListType;
  @Input() skillId: number;
  @Input() mode: 'update' | 'selection' = 'selection';
  @Input() canGoBack = true;
  @Output() askValidation = new EventEmitter<boolean>();
  @Output() wasUpdated = new EventEmitter<boolean>();
  onChange: (obj: any) => void;
  onTouched: () => void;
  activeTab = 0;
  tabs: {name: string, slug: string}[] = [
    {name: _('evaluator.TOUS'), slug: 'all'},
    {name: _('evaluator.FAVORIS'), slug: 'favorites'},
    {name: _('evaluator.PROFESSIONNELS'), slug: 'pro'},
  ];
  contacts: Contact[];
  displayContacts: Contact[];
  searchQuery = '';
  contactSelection: { [email: string]: boolean } = {};
  selectedContacts: Contact[];
  skill: Skill;
  stat: SkillStatistics;
  private intialCheckEmails: string[];
  private authData: AuthData;
  protected showFbCriterias: boolean;

  constructor(
      private readonly authService: AuthService,
      private readonly contactService: ContactService,
      private readonly navCtrl: NavController,
      private readonly modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.authService.authStatus.subscribe({
      next: status => {
        if (status.authDone && status.authData && !this.authData) {
          this.authData = status.authData;
          if (this.skillId) {
            this.stat = this.authData.data.gamification.getSkillStat(this.skillId);
            this.skill = this.authData.data.user.skills.find(s => s.id === this.skillId);
            if (!this.skill) {
              this.skill = this.authData.data.events.find(e => e.id === this.skillId);
            }
            this.showFbCriterias = this.skill?.hasFeedbackCriteria();
            console.log(this.skill);
          }
          this.loadContacts();
        }
      }
    });
  }

  private async loadContacts() {
    const type = this.type !== 'school' ? this.type : null;
    let contacts = await this.contactService.getAllContacts(type, this.skillId);

    // If mode update and type is pro, select tab 2
    if (this.mode === 'update' && this.type === 'pro') {
      this.activeTab = 2;
    }

    // If type school keep only school_admin, school_referent, teacher
    if (this.type === 'school') {
      contacts = contacts.filter(c => ['school_admin', 'school_referent', 'teacher'].includes(c.type));
    }

    // If updating lists, keep only contacts that are not from the school
    if (this.type !== 'school' && this.mode === 'update') {
      contacts = contacts.filter(c => !['school_admin', 'school_referent', 'teacher'].includes(c.type));
    }

    // If updating a list, we should remove contacts from other lists (pro, external_pro, sport_asso)
    // Other types are not concerned
    if (this.mode === 'update' && this.type !== 'school') {
      contacts = contacts.filter(c => {
        // Exclude tutor if type is not pro
        if (c.type === 'tutor' && this.type !== 'pro') {
          return false;
        }
        if (['pro', 'external_pro', 'sport_asso'].includes(c.type)) {
          return c.type === this.type;
        }
        return true;
      });
    }

    // When listing contacts for selection (feedbacks), check the skill criteria
    // Remove contacts that are not in the authorized list
    if (this.mode === 'selection' && this.skill?.hasClosedFeedbackCriteria() && !this.skill?.hasOpenedFeedbackCriteria()) {
      let approvedTypes: ContactType[] = [];
      this.skill.skill_badge_criterions.forEach(c => {
        if (c.modality !== 'closed_feedbacks') {
          return;
        }
        switch (c.closed_feedbacks_detail) {
          case 'pros':
          case 'tutor':
            approvedTypes.push('pro');
            approvedTypes.push('tutor');
            break;
          case 'external_pros':
            approvedTypes.push('external_pro');
            break;
          case 'school_members':
            approvedTypes.push('school_admin', 'school_referent', 'teacher');
            break;
          case 'associative':
            approvedTypes.push('sport_asso');
            break;
        }
      });
      // Remove duplicate entries in approvedTypes
      approvedTypes = approvedTypes.filter((v, i, a) => a.indexOf(v) === i);

      // Filter contacts by approvedTypes
      contacts = contacts.filter(c => approvedTypes.includes(c.type));

      // If no contact is available, display a modal
      if (contacts.length === 0) {
        const modal = await this.modalCtrl.create({
          component: NoContactModalComponent,
          componentProps: {
            types: approvedTypes,
          },
          cssClass: 'modal-auto-height'
        });
        modal.present();
        modal.onWillDismiss().then((event) => {
          if (event.data?.yes) {
            this.createContact();
          }
        });
      }
    }

    // Create selection array
    this.contactSelection = {};
    for (const contact of contacts) {
      const checked = this.checkDefault(contact);
      this.contactSelection[contact.email] = checked;
      contact.checked = checked;
      // Force title if contact is already selected
      if (checked) {
        contact.title = this.contactService.getContactTitle(this.type);
      }
    }

    // Sort contacts
    this.contactService.sortContacts(contacts);

    this.contacts = contacts;
    this.displayContacts = contacts;

    // Init selected contacts
    this.onChange?.(this.contacts.filter(c => c.checked));

    // Save initial state
    this.intialCheckEmails = this.contacts
        .filter(c => c.checked)
        .map(c => c.email);
    this.wasUpdated.emit(false);
    this.askValidation.emit(this.contacts.some(c => !c.isValid));

    this.filterContacts();
  }

  navigateBack() {
    this.navCtrl.back();
  }

  selectTab(i: number) {
    this.activeTab = i;
    this.filterContacts();
  }

  async createContact() {
    const modal = await this.modalCtrl.create({
      component: AddContactPage,
      componentProps: {
        type: this.type,
        mode: this.mode,
        contacts: this.contacts,
      },
      // cssClass: 'modal-auto-height'
    });
    modal.present();
    modal.onDidDismiss().then(data => {
      if (data.data?.added) {
        this.contacts.push(data.data.contact);
        this.selectContact(data.data.contact);
        this.filterContacts();
      }
    });
  }

  writeValue(obj: any): void {
    if (!this.selectedContacts) {
      this.selectedContacts = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  filterContacts() {
    let contacts = [];
    switch (this.activeTab) {
      case 0:
        // All
        contacts = this.contacts;
        break;
      case 1:
        // Favorites
        const alreadyRequested = [];
        for (const req of this.authService.authStatus.value.authData.data.alreadyRequested) {
          for (const recipient of req.recipients) {
            alreadyRequested.push(recipient.email);
          }
        }
        contacts = this.contacts.filter(c => alreadyRequested.includes(c.email));
        break;
      case 2:
        // Pros
        contacts = this.contacts.filter(c =>
            ['pro', 'tutor', 'external_pro'].includes(c.type)
        );
        break;
    }

    this.displayContacts = this.contactService.filterContacts(contacts, this.searchQuery);
  }

  private checkDefault(contact: Contact): boolean {
    // If mode is selection, check nothing
    if (this.mode === 'selection' || contact.source === 'local' || contact.source === 'device') {
      return false;
    }

    if (this.type === 'pro' && this.mode === 'update' && contact.type === 'tutor') {
      return true;
    }

    // If type is school and mode is update, check everything
    if (this.type === 'school' && this.mode === 'update') {
      return true;
    }

    // If contact type is the same as the list type, check it
    if (contact.type === this.type) {
      return true;
    }

    // If contact is sent to the component, check it
    return this.selectedContacts.find(c => c.email === contact.email) !== undefined;
  }

  listChanged() {
    const contacts = this.contacts.filter(c => this.contactSelection[c.email]);
    this.onChange?.(contacts);
    // Check if list was updated (added or removed contacts)
    for (const contact of contacts) {
      if (!this.intialCheckEmails.includes(contact.email)) {
        this.wasUpdated.emit(true);
        return;
      }
    }
    for (const email of this.intialCheckEmails) {
      if (!this.contactSelection[email]) {
        this.wasUpdated.emit(true);
        return;
      }
    }
    this.wasUpdated.emit(false);
  }

  private async checkContactTitle(contact: Contact) {
    // Check type allows updates
    // Liste établissement en mode maj de liste
    if (this.type === 'school' && this.mode === 'update') {
      return;
    }
    const isSelected = !this.contactSelection[contact.email];
    // Check if contact needs a title
    const needsTitle = this.mode === 'update' && ['pro', 'external_pro', 'sport_asso'].includes(this.type);
    // If contact has no title, ask for it
    if (needsTitle && !contact.title && isSelected) {
      const modal = await this.modalCtrl.create({
        component: AddContactTitleComponent,
        componentProps: {
          contacts: [contact],
          type: this.type,
        },
        cssClass: 'modal-auto-height'
      });
      modal.present();
      modal.onWillDismiss().then(response => {
        if (response.data?.title) {
          this.contactSelection[contact.email] = isSelected;
          contact.title = response.data.title;
          this.listChanged();
        } else {
          this.contactSelection[contact.email] = false;
        }
      });
    } else {
      this.contactSelection[contact.email] = isSelected;
      this.listChanged();
    }
  }

  selectContact(contact: Contact) {
    // Check if contact is tutor (update), we can't unselect it
    if (contact.type === 'tutor' && this.mode === 'update') {
      return;
    }

    // If mode is selection, check if we're allowed to select the contact (feedbacks date, etc.)
    if (this.mode === 'selection' && !this.canSelect(contact)) {
      return;
    }
    this.checkContactTitle(contact);
  }

  canSelect(contact: Contact): boolean {
    if (this.mode === 'update' || !this.skillId || contact.lastContact === undefined || this.skill?.is_event) {
      return true;
    }

    // Last contact should be at least 90 days ago
    return contact.lastContact < Date.now() - (ContactHelper.DAYS_BEFORE_AVAILABLE * 24 * 60 * 60 * 1000);
  }
}
