<div class="container">
    <div class="fs-2 d-flex justify-content-between align-items-center text-green gap-2">
        <div class="d-flex align-items-center">
            <span class="icon coss-arrow-left clickable" *ngIf="type === 'feedback'" (click)="navigateBack()"></span>
        </div>
        <div class="d-flex align-items-center justify-content-end">
            <span class="icon coss-trash clickable" *ngIf="type !== 'onboarding' && !selfEvaluation" (click)="ignoreFeedback()"></span>
            <ion-icon name="close-outline" class="clickable" *ngIf="type === 'onboarding'" (click)="nextOnboardingStep()"></ion-icon>
        </div>
    </div>
    <h1 class="mt-1 mb-1" *ngIf="user && !selfEvaluation">{{ 'answer.REPONDRE_A'|translate: {name: user.firstname } }}</h1>
    <h1 class="mt-1 mb-1" *ngIf="selfEvaluation">{{ 'feedback.MON_AUTO_EVALUATION'|translate }}</h1>

    <ng-container *ngIf="recipient">
        <div class="card py-1 px-2" [formGroup]="answerForm" *ngIf="!recipient.email || !recipient.firstname || !recipient.lastname">
            <!-- NOM -->
            <div class="form-group py-1" *ngIf="!recipient.firstname || !recipient.lastname">
                <label>{{ 'guest.NOM' | translate }}</label>
                <input type="text" class="form-control" placeholder="{{ 'guest.SAISIR_NOM' | translate }}" formControlName="lastname">
            </div>
            <!-- PRENOM -->
            <div class="form-group py-1" *ngIf="!recipient.firstname || !recipient.lastname">
                <label>{{ 'guest.PRENOM' | translate }}</label>
                <input type="text" class="form-control" placeholder="{{ 'guest.SAISIR_PRENOM' | translate }}" formControlName="firstname">
            </div>
            <!-- EMAIL -->
            <div class="form-group py-1" *ngIf="!recipient.email">
                <label>{{ 'guest.EMAIL' | translate }}</label>
                <input type="email" class="form-control" placeholder="{{ 'guest.SAISIR_EMAIL' | translate }}" formControlName="email">
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!feedback else answers">
        <!-- Loader -->
        <div class="py-4 text-center text-black fs-4">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
    </ng-container>

    <ng-template #answers>
        <div class="tab-selector mb-2">
            <div class="tab-selector-inner">
                <button type="button" class="btn btn-rounded tab-selector-item"
                        (click)="selectFeedback(f)"
                        [class.active]="f.id === feedback.id"
                        *ngFor="let f of feedbacks; let i = index"
                >
                    {{ f.skill.title }}
                </button>
            </div>
        </div>

        <div class="card p-2 mb-2" *ngFor="let question of feedback.skill.questions">
            <p class="mb-2">{{ (selfEvaluation ? question.title_self_evaluation : question.title|question: user.firstname) }}</p>
            <app-star-rating [(ngModel)]="question.score" [noOpinionAvailable]="!selfEvaluation"></app-star-rating>
        </div>

        <ng-container *ngIf="tagItems?.length > 0">
            <h3 class="mt-2 mb-1">{{ 'rating.POINTS_FORTS'|translate }}</h3>
            <div class="card">
                <app-button-group [(ngModel)]="selectedStrengthTags"
                                  (ngModelChange)="removeTags('strengths', $event)"
                                  [items]="strengthItems" [multiple]="true"></app-button-group>
            </div>

            <h3 class="mt-2 mb-1">{{ 'rating.POINTS_FAIBLES'|translate }}</h3>
            <div class="card">
                <app-button-group [(ngModel)]="selectedWeaknessTags"
                                  (ngModelChange)="removeTags('weaknesses', $event)"
                                  [items]="weaknessItems" [multiple]="true"></app-button-group>
            </div>
        </ng-container>

        <ng-container *ngIf="hasRecommendation">
            <h3 class="mt-2 mb-1">{{ 'rating.RECOMMANDATION'|translate }} <span class="fw-400">{{ 'rating.FACULTATIF' |translate }}</span></h3>
            <div class="card p-2">
                <p>
                    {{ 'rating.RECOMMANDATION_DESCRIPTION'|translate }}
                </p>
                <div class="form-group mt-2">
                    <textarea class="form-control" rows="5" [(ngModel)]="recommendation" placeholder="{{ 'rating.RECOMMANDATION_PLACEHOLDER'|translate: {firstname: user.firstname } }}"></textarea>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="user && !selfEvaluation">
            <h3 class="mt-2 mb-1">{{ 'rating.POUR_ALLER_PLUS_LOIN'|translate }}</h3>
            <div class="card p-2">
                <div class="d-flex justify-content-between align-items-center gap-2">
                    <label class="w-100 d-flex justify-content-start align-items-center gap-2">
                        <span class="icon coss-coffee-cup text-blue fs-2"></span>
                        <p class="fw-600 fs-4">{{ 'rating.DEMANDE_CAFE_TITLE'|translate }}</p>
                    </label>
                    <ion-checkbox mode="md" [(ngModel)]="coffeeRequested"></ion-checkbox>
                </div>
                <p class="mt-1 text-blue" [innerHTML]="'rating.DEMANDE_CAFE_LABEL'|translate: {name: user.firstname}"></p>
            </div>
        </ng-container>

        <button type="button" class="btn btn-green btn-rounded btn-block mt-3" (click)="sendFeedback()">
            {{ 'rating.ENVOYER_REPONSE'|translate }}
        </button>
    </ng-template>
</div>
