<div class="container">
  <p class="fs-3 text-center">
    {{ 'request-confirmation.NOTIFY' | translate }}
  </p>
  <div class="d-flex gap-2 mt-4">
    <button class="btn btn-block btn-outlined btn-green btn-rounded" (click)="close()">{{ 'NON'|translate }}</button>
    <button class="btn btn-block btn-primary btn-green btn-rounded" (click)="send()">{{ 'OUI'|translate }}</button>
  </div>
  <div class="mt-2 clickable d-flex justify-content-start gap-2 align-items-center" (click)="toggleDontNotify()">
    <ion-icon *ngIf="dontNotify" name="checkmark-circle" class="fs-3 text-green"></ion-icon>
    <ion-icon *ngIf="!dontNotify" name="ellipse-outline" class="fs-3 text-blue-dark"></ion-icon>
    <div>
      {{ 'request-confirmation.NOTIFY_NE_PAS_DEMANDER'|translate }}
    </div>
  </div>
</div>
