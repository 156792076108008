import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-add-contact-title',
    templateUrl: './add-contact-title.component.html',
    styleUrls: ['./add-contact-title.component.scss'],
})
export class AddContactTitleComponent  implements OnInit {
    formGroup = new FormGroup({
        title: new FormControl('', Validators.required),
    });

    constructor(
        private readonly modalCtrl: ModalController,
    ) { }

    ngOnInit() {}

    close(){
        this.modalCtrl.dismiss({added: false});
    }

    addContact(){
        const data = this.formGroup.value;
        this.modalCtrl.dismiss({title: data.title});
    }
}
