import {NgModule} from '@angular/core';
import {
    PreloadAllModules,
    RouterModule,
    Routes,
} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module')
            .then( m => m.HomePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks',
        loadChildren: () => import('./pages/feedbacks/feedback/feedback.module')
            .then(m => m.FeedbackPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/ask',
        loadChildren: () => import('./pages/feedbacks/feedback-ask/feedback-ask.module').then(m => m.FeedbackAskPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/list',
        loadChildren: () => import('./pages/feedbacks/feedback-list/feedback-list.module')
            .then(m => m.FeedbackListPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/rating/confirmation',
        loadChildren: () => import('./pages/feedbacks/feedback-rating-confirmation/feedback-rating-confirmation.module').then( m => m.FeedbackRatingConfirmationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/rating/:id',
        loadChildren: () => import('./pages/feedbacks/feedback-rating/feedback-rating.module')
            .then(m => m.FeedbackRatingPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/ask/pick-skills',
        loadChildren: () => import('./pages/feedbacks/feedback-ask-pick-skills/feedback-ask-pick-skills.module').then( m => m.FeedbackAskPickSkillsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/results',
        loadChildren: () => import('./pages/feedbacks/feedback-results/feedback-results.module').then(m => m.FeedbackResultsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'feedbacks/pick-evaluator',
        loadChildren: () => import('./pages/feedbacks/feedback-pick-evaluator/feedback-pick-evaluator.module').then(m => m.FeedbackPickEvaluatorPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/stats/:id',
        loadChildren: () => import('./pages/feedbacks/feedback-stats/feedback-stats.module').then(m => m.FeedbackStatsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'feedbacks/request/confirmation',
        loadChildren: () => import('./pages/feedbacks/feedback-request-confirmation/feedback-request-confirmation.module').then( m => m.FeedbackRequestConfirmationModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module')
            .then( m => m.ProfilePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile/change-course',
        loadChildren: () => import('./pages/change-course/change-course.module')
            .then( m => m.ChangeCourseModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile/manage-contact-list',
        loadChildren: () => import('./pages/manage-contact-list/manage-contact-list.module').then( m => m.ManageContactListPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module')
            .then( m => m.LoginPageModule)
    },
    {
        path: 'badge',
        loadChildren: () => import('./pages/badges/badge/badge.module')
            .then(m => m.BadgePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'challenges',
        loadChildren: () => import('./pages/challenge/challenges/challenges.module')
            .then(m => m.ChallengesPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'challenges/start/:challenge_id',
        loadChildren: () => import('./pages/challenge/challenges-start/challenges-start.module')
            .then(m => m.ChallengesStartPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'challenges/feedback/:challenge_id',
        loadChildren: () => import('./pages/challenge/challenges-feedback/challenges-feeedback.module')
            .then(m => m.ChallengesFeedbackPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'challenges/result',
        loadChildren: () => import('./pages/challenge/challenges-result/challenges-result.module')
            .then( m => m.ChallengesResultPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'challenges/question/:challenge_id',
        loadChildren: () => import('./pages/challenge/challenges-question/challenges-question.module')
            .then( m => m.ChallengesQuestionPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'badge/family',
        loadChildren: () => import('./pages/badges/badge-family/badge-family.module')
            .then(m => m.BadgeFamilyPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'badge/stats/:id',
        loadChildren: () => import('./pages/badges/badge-stats/badge-stats.module')
            .then(m => m.BadgeStatsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'badge/wallet/:id',
        loadChildren: () => import('./pages/badges/wallet/wallet.module').then( m => m.WalletPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'live-update',
    //     loadChildren: () => import('./pages/live-update/live-update.module').then( m => m.LiveUpdatePageModule)
    // },
    {
        path: 'self-evaluation',
        loadChildren: () => import('./pages/self-evaluation/self-evaluation.module').then( m => m.SelfEvaluationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'self-evaluation/pick',
        loadChildren: () => import('./pages/self-evaluation/self-evaluation-pick/self-evaluation-pick.module').then( m => m.SelfEvaluationPickPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'self-evaluation/answer',
        loadChildren: () => import('./pages/self-evaluation/self-evaluation-answer/self-evaluation-answer.module').then( m => m.SelfEvaluationAnswerPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'update',
        loadChildren: () => import('./pages/update/update.module').then( m => m.UpdatePageModule)
    },
    {
        path: 'grid/:id',
        loadChildren: () => import('./pages/grid/grid.module').then( m => m.GridPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'guest-answer-grid/:token/:id',
        loadChildren: () => import('./pages/guest/guest-grid/guest-grid.module').then( m => m.GuestGridPageModule)
    },
    {
        path: 'guest-answer/:token/:id',
        loadChildren: () => import('./pages/guest/guest-feedback/guest-feedback.module').then( m => m.GuestFeedbackPageModule)
    },
    {
        path: 'guest-answer/confirmation',
        loadChildren: () => import('./pages/guest/guest-confirmation/guest-confirmation.module').then( m => m.GuestConfirmationPageModule)
    },
    {
        path: 'explain-skills/:familyId',
        loadChildren: () => import('./pages/explain-skills/explain-skills.module').then( m => m.ExplainSkillsPageModule)
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
