import {marker as _} from '@colsen1991/ngx-translate-extract-marker';
import {SkillBadgeCriterion, UserType} from '../models/gamification';
import {School} from '../models/learning-period';
import {Skill} from '../models/skill';
import {TranslateService} from '../services/translate.service';

export type BadgeCriteriaDetails = {
    feedbackCriterias: string[],
    gradeCriterias: string[],
    totalFeedbacks: number,
    hasGrades: boolean,
    hasFeedbacks: boolean
};

export class CriteriaHelper {

    static userTypeToLabel(userType: UserType|'teacher', empty: string = ''): string {
        switch (userType) {
            case 'tutor':
                return _('feedback.criteria.missingTutorFeedbacks');
            case 'pros':
                return _('feedback.criteria.missingProFeedbacks');
            case 'school_members':
                return _('feedback.criteria.MEMBRE_DE_LECOLE');
            case 'teacher':
                return _('feedback.criteria.PROFESSEUR');
            case 'external_pros':
                return _('feedback.criteria.PRO_EXTERNE');
            case 'associative':
                return _('feedback.criteria.ASSOCIATIF');
            default:
                return `(${empty}) NaN`;
        }
    }

    static getBadgeCriteriaDetails(skill: Skill, school: School, translateService: TranslateService): BadgeCriteriaDetails {
        const result = {
            hasGrades: skill.hasGradeCriteria(),
            hasFeedbacks: false,
            feedbackCriterias: [],
            gradeCriterias: [],
            totalFeedbacks: 0,
        };

        console.log(school);

        // Initialize the variables
        let criterias: SkillBadgeCriterion[] = skill.skill_badge_criterions;
        // If empty skill criteria, add a default criteria : opened_feedbacks
        if (!skill.skill_badge_criterions
            || skill.skill_badge_criterions.length === 0
            || skill.skill_badge_criterions.every(c => !c.mandatory)) {
            criterias = [{
                mandatory: true,
                modality: 'opened_feedbacks',
                subject: null,
                value: 0,
                closed_feedbacks_detail: null,
            }];
        }

        result.hasFeedbacks = skill.hasFeedbackCriteria(criterias);

        // Check if we have grades and feedbacks criterias
        let openedFeedbacks = false;

        // For each criteria, we check if it is mandatory and we add it to the correct list
        for (const criteria of criterias) {
            // We skip the criteria if it is not mandatory
            if (!criteria.mandatory) {
                continue;
            }

            // We add the criteria to the correct list
            if (criteria.modality.startsWith('note_')) {
                // --------- Grade
                result.gradeCriterias.push(translateService.get(_('explain-skills.CRITERE_NOTE'), {
                    subject: criteria.subject.title,
                    grade: criteria.value,
                }));
            } else {
                // --------- Open feedback
                if (criteria.modality === 'opened_feedbacks') {
                    if (openedFeedbacks) {
                        // We already have an opened feedback criteria, we skip this one
                        continue;
                    }
                    openedFeedbacks = true;
                    // Not mad skills
                    // --------- Not Mad Skills (soft, hard, etc.)
                    if (skill.family?.badge_type !== 'mad_skills') {
                        const fields: {name: 'pro'|'tutor'|'teacher', label: 'pros'|'tutor'|'teacher'}[] = [
                            {name: 'pro', label: 'pros'},
                            {name: 'tutor', label: 'tutor'},
                            {name: 'teacher', label: 'teacher'}
                        ];
                        result.totalFeedbacks = school.quality_all_feedback_count_a || 1;
                        for (const field of fields) {
                            const key = `quality_${field.name}_feedback_count_a`;
                            if (!school[key] || school[key] < 1) {
                                continue;
                            }
                            const label = CriteriaHelper.userTypeToLabel(field.label);
                            result.feedbackCriterias.push(translateService.get(label, {count: school[key], school}));
                        }
                    } else {
                        // --------- Mad Skills
                        const key = `quality_mad_associative_feedback_count_a`;
                        result.totalFeedbacks = school.quality_mad_all_feedback_count_a || 1;
                        if (!school[key] || school[key] < 1) {
                            continue;
                        }
                        const label = CriteriaHelper.userTypeToLabel('associative');
                        result.feedbackCriterias.push(translateService.get(label, {count: school[key], school}));
                    }
                }
                // --------- Closed feedback
                if (criteria.modality === 'closed_feedbacks') {
                    // Closed feedback
                    // For each type of user, check if we have enough feedbacks
                    const label = CriteriaHelper.userTypeToLabel(criteria.closed_feedbacks_detail);
                    const value = criteria.value;
                    if (!value || value < 1) {
                        continue;
                    }
                    result.totalFeedbacks += value;
                    result.feedbackCriterias.push(translateService.get(label, {count: value}));
                }
            }
        }

        return result;
    }

}
