import {Component, Input, OnInit} from '@angular/core';
import {Skill} from '../../../models/skill';
import {SkillStatistics, UserType} from '../../../models/gamification';
import {TranslateService} from '../../../services/translate.service';
import {marker as _} from '@colsen1991/ngx-translate-extract-marker';
import {CriteriaHelper} from '../../../utils/criteria.helper';
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-fb-criteria',
    templateUrl: './fb-criteria.component.html',
    styleUrls: ['./fb-criteria.component.scss'],
})
export class FbCriteriaComponent  implements OnInit {

    missingFeedbackList: string[] = [];
    skillStatistics: SkillStatistics;
    totalMissingFeedbacks: number;
    hasMissingFeedbacks = false;
    isOpenFeedback = false;
    qualityIndex: 'A' | 'B' | 'C';

    @Input() skill: Skill;
    @Input() stat: SkillStatistics;

    constructor(
        private readonly authService: AuthService,
        private readonly translateService: TranslateService
    ) {
    }

    ngOnInit() {
        if (this.skill && (!this.stat || this.skillStatistics)) {
            const school = this.authService.authStatus.value.authData.data.learningPeriod.school_path.school;
            const details = CriteriaHelper.getBadgeCriteriaDetails(this.skill, school, this.translateService);
            this.hasMissingFeedbacks = details.hasFeedbacks;
            this.totalMissingFeedbacks = details.totalFeedbacks;
            this.missingFeedbackList = details.feedbackCriterias;
        } else {
            this.skillStatistics = this.stat;
            this.qualityIndex = this.stat.qualityIndex;
            this.computeList();
        }
    }

    private computeList() {
        this.totalMissingFeedbacks = 0;
        const criterias = this.skillStatistics.skill.skill_badge_criterions || [];
        if (criterias.length === 0) {
            criterias.push({modality: 'opened_feedbacks', mandatory: true, closed_feedbacks_detail: null, value: 0, subject: null});
        }
        console.log(criterias);
        for (const criteria of criterias) {
            if (!criteria.mandatory) {
                continue;
            }

            if (criteria.modality === 'opened_feedbacks') {
                // Open feedback : check if we have enough feedbacks
                this.isOpenFeedback = true;
                if (this.skillStatistics.skill.isMadSkill()) {
                    // Mad skills
                    if (this.skillStatistics.missingMadAssociativeFeedbacks > 0) {
                        this.missingFeedbackList.push(this.translateService.get(
                            _('feedback.criteria.missingMadAssociativeFeedbacks'),
                            {count: this.skillStatistics.missingMadAssociativeFeedbacks}
                        ));
                        this.hasMissingFeedbacks = true;
                    }
                    this.totalMissingFeedbacks = Math.max(this.skillStatistics.missingMadAllFeedbacks, this.skillStatistics.missingMadAssociativeFeedbacks);
                    if (this.skillStatistics.missingMadAllFeedbacks > 0) {
                        this.hasMissingFeedbacks = true;
                    }
                } else {
                    let missingFeedbacks = 0;
                    for (const category of [
                        _('feedback.criteria.missingProFeedbacks'),
                        _('feedback.criteria.missingTutorFeedbacks'),
                        _('feedback.criteria.missingTeacherFeedbacks'),
                    ]) {
                        const value = this.skillStatistics[category.split('.').pop()];
                        if (value > 0) {
                            this.missingFeedbackList.push(this.translateService.get(category, {count: value}));
                            this.hasMissingFeedbacks = true;
                            missingFeedbacks += value;
                        }
                    }

                    // total missing feedbacks should be at least the number of missing feedbacks for the category
                    this.totalMissingFeedbacks = Math.max(this.skillStatistics.missingAllFeedbacks, missingFeedbacks);
                    if (this.totalMissingFeedbacks > 0) {
                        this.hasMissingFeedbacks = true;
                    }
                }
                break;
            } else if (criteria.modality === 'closed_feedbacks') {
                // For each type of user, check if we have enough feedbacks
                const label = CriteriaHelper.userTypeToLabel(criteria.closed_feedbacks_detail, '' + criteria.value);
                const value = criteria.value - this.checkUserType(criteria.closed_feedbacks_detail);
                // If we have missing feedbacks, add it to the list
                if (value > 0) {
                    console.log(this.totalMissingFeedbacks);
                    this.totalMissingFeedbacks += value;
                    this.hasMissingFeedbacks = true;
                    this.missingFeedbackList.push(this.translateService.get(label, {count: criteria.value}));
                }
            }
        }
    }

    private checkUserType(type: UserType): number {
        return this.skillStatistics.answeringUsers.filter(u => u.isType(type)).length;
    }
}
