import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import {Observable, of} from 'rxjs';
import {UserService} from './user.service';
import {ModalController} from '@ionic/angular';
import {OnboardingDashboardPage} from '../modals/onboarding/onboarding-dashboard/onboarding-dashboard.page';
import {OnboardingAnswerPage} from '../modals/onboarding/onboarding-answer/onboarding-answer.page';
import {OnboardingSlidesPage} from '../modals/onboarding/onboarding-slides/onboarding-slides.page';
import {OnboardingSkillsPage} from '../modals/onboarding/onboarding-skills/onboarding-skills.page';
import {
    OnboardingSelfEvaluationPage
} from '../modals/onboarding/onboarding-self-evaluation/onboarding-self-evaluation.page';
import {OnboardingContactsPage} from '../modals/onboarding/onboarding-contacts/onboarding-contacts.page';
import {
    OnboardingChallengesComponent
} from '../modals/onboarding/onboarding-challenges/onboarding-challenges.component';
import {OnboardingFinishedPage} from '../modals/onboarding/onboarding-finished/onboarding-finished.page';
import {OnboardingBlankPage} from '../modals/onboarding/onboarding-blank/onboarding-blank.page';
import {OnboardingTermsPage} from '../modals/onboarding/onboarding-terms/onboarding-terms.page';
import {AddContactPage} from '../modals/add-contact/add-contact.page';
import {
    OnboardingPickCourseComponent
} from "../modals/onboarding/onboarding-pick-course/onboarding-pick-course.component";
import {FeedbackService} from "./feedback.service";
import {SkillService} from "./skill.service";

export type OnboardingStep = 'terms' | 'pick-course' | 'dashboard' | 'answer' | 'challenges' | 'slides' | 'skills' | 'self-evaluation' | 'contacts' | 'finished' | 'blank';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    modals: {[key: string]: any} = {
        dashboard: OnboardingDashboardPage,
        answer: OnboardingAnswerPage,
        slides: OnboardingSlidesPage,
        skills: OnboardingSkillsPage,
        'self-evaluation': OnboardingSelfEvaluationPage,
        contacts: OnboardingContactsPage,
        challenges: OnboardingChallengesComponent,
        finished: OnboardingFinishedPage,
        'pick-course': OnboardingPickCourseComponent,
        blank: OnboardingBlankPage,
        terms: OnboardingTermsPage
    };
    currentModal: HTMLIonModalElement = null;

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly modalCtrl: ModalController,
        private readonly feedbackService: FeedbackService,
        private readonly skillService: SkillService,
    ) { }

    doOnboarding(type: 'global' | 'challenge' | 'answer' | 'dashboard' = 'global'): boolean {
        const variable = 'is_onboarding_' + (type !== 'global' ? type + '_' : '') + 'finished';
        const isDone = this.authService.authStatus.value?.authData?.data?.user[variable] || false;
        return isDone === false;
    }

    onboardingFinished(type: 'global' | 'challenge' | 'answer' | 'dashboard' = 'global'): Observable<boolean> {
        const user = this.authService.authStatus.value?.authData;
        if (!user) {
            return of(false);
        }

        const route = '/api/user/onboarding/' + (type !== 'global' ? type + '/' : '') + 'finished';
        const variable = 'is_onboarding_' + (type !== 'global' ? type + '_' : '') + 'finished';

        user.data.user[variable] = true;
        return this.apiService.get<any>(route).pipe(map(
            res => {
                this.userService.saveUser(res);
                return true;
            }),
        );
    }

    async goTo(
        step: OnboardingStep,
        closeModal = true,
        openProperties: any = {},
        closeData: any = {},
    ) {
        // Close the current modal
        if (closeModal && this.currentModal) {
            await this.currentModal.dismiss(closeData);
        }
        this.currentModal = null;

        // Open the new modal
        const newModal = await this.modalCtrl.create({
            component: this.modals[step],
            componentProps: openProperties,
            cssClass: 'onboarding-modal',
            backdropDismiss: false,
            keyboardClose: false,
        });
        await newModal.present();

        // Save the new modal
        this.currentModal = newModal;

        return newModal;
    }

    async close(data?: any) {
        if (this.currentModal) {
            await this.currentModal.dismiss(data);
        }
    }

    hasAutoEval(): boolean {
        return this.feedbackService.hasPendingAutoEval();
    }

    hasSkillsToPick(): boolean {
        return this.skillService.canPickMoreSkills(5);
    }

    async skillOrSkip() {
        // Otherwise, go to the skills selection page if we have skills to pick
        if (this.hasSkillsToPick()) {
            await this.goTo('skills', true);
        } else {
            // Check if we have auto eval to do
            if (this.hasAutoEval()) {
                await this.goTo('self-evaluation', true);
            } else {
                // We're done
                await this.goTo('finished');
            }
        }
    }
}
