<app-card-collapse [asCard]="false" titleClass="fs-2 fw-bold" [title]="familyGroup.family.title" paddingSize="py-1" *ngFor="let familyGroup of familyGroups">
    <app-card-collapse [asCard]="false" titleClass="fs-3 fw-bold" *ngFor="let cat of familyGroup.categories" paddingSize="py-1" [title]="cat.category.title">
        <div class="card no-shadow p-2 d-flex flex-column justify-content-start align-items-start gap-1">
            <div class="w-100 d-flex justify-content-between align-items-center" *ngFor="let skill of getSkills(cat, familyGroup)">
                <div class="w-100 d-flex justify-content-start align-items-center gap-1 clickable" (click)="toggleSkill(skill)">
                    <div class="d-flex justify-content-start align-items-center">
                        <ion-icon name="checkbox" class="text-green" *ngIf="selected(skill)"></ion-icon>
                        <ion-icon name="square-outline" class="text-blue-dark" *ngIf="!selected(skill)"></ion-icon>
                    </div>
                    {{ skill.title }}
                </div>
                <div class="clickable">
                    <app-tooltip [title]="skill.title" [text]="skill.description" *ngIf="skill.description"></app-tooltip>
                </div>
            </div>
            <div class="w-100 text-center" *ngIf="shouldDisplayToggle(cat, familyGroup)">
                <button class="btn btn-link btn-green" (click)="toggleShowMore(cat, familyGroup)">
                    <ng-container *ngIf="!isShowingMore(cat, familyGroup)">{{ 'SEE_ALL'|translate }}</ng-container>
                    <ng-container *ngIf="isShowingMore(cat, familyGroup)">{{ 'HIDE_ALL'|translate }}</ng-container>
                </button>
            </div>
        </div>
    </app-card-collapse>
</app-card-collapse>
