import { Injectable } from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {EmailComposer} from 'capacitor-email-composer';
import {marker as _} from '@colsen1991/ngx-translate-extract-marker';
import {Browser} from '@capacitor/browser';
import {TranslateService} from './translate.service';


@Injectable({
    providedIn: 'root'
})
export class ShareService {

    constructor(
        private readonly platform: Platform,
        private readonly translateService: TranslateService,
        private readonly alertCtrl: AlertController
    ) {
    }

    public async shareByMail(to: string[], subject: string, body: string): Promise<void> {
        if (!this.platform.is('capacitor')) {
            // Replace \n by %0D%0A for the body
            body = encodeURIComponent(body).replace(/\n/g, '%0D%0A');
            // Open a browser link
            return Browser.open({
                url: `mailto:?subject=${encodeURIComponent(subject)}&body=${body}&to=${encodeURIComponent(to.join(','))}`,
            });
        }

        // If platform is IOS, check if the email composer has account
        if (this.platform.is('ios')) {
            const hasAccount = (await EmailComposer.hasAccount()).hasAccount;
            if (!hasAccount) {
                const alert = await this.alertCtrl.create({
                    header: this.translateService.get(_('email-composer.NO_ACCOUNT')),
                    buttons: [this.translateService.get(_('OK'))]
                });
                await alert.present();
                return;
            }
        }

        // Open the email composer
        // Replace \n by <br> for the body
        body = body.replace(/\n/g, '<br>');
        return EmailComposer.open({isHtml: true, subject, to, body});
    }

}
