import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NavController, Platform, LoadingController, ToastController } from '@ionic/angular';
import {PushNotifications} from '@capacitor/push-notifications';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '../../../services/translate.service';
import {AuthData} from '../../../models/auth-status';
import {OnboardingService} from '../../../services/onboarding.service';
import {PushNotificationsService} from '../../../services/push-notifications.service';
import {SwiperComponent} from 'swiper/angular';
import {SwiperOptions, Swiper} from 'swiper/types';
import {marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
    selector: 'app-onboarding-slides',
    templateUrl: './onboarding-slides.page.html',
    styleUrls: ['./onboarding-slides.page.scss'],
})
export class OnboardingSlidesPage implements OnInit {
    private swiper: Swiper;

    @ViewChild('slides', {static: false}) set slides(el: SwiperComponent) {
        if (el) {
            this.swiper = el.swiperRef;
            this.sliderReady = true;
        }
    }
    @ViewChild('slidePagination', {static: false}) set slidePagination(el: ElementRef) {
        if (el) {
            this.swiperConfig = {
                slidesPerView: 1,
                allowTouchMove: false,
                pagination: {
                    el: el.nativeElement,
                }
            };
        }
    }
    public authData: AuthData;
    public skills = [] as any;
    public displayNotificationButtons = false;
    swiperConfig: SwiperOptions;
    sliderReady = false;

    constructor(
        private readonly navCtrl: NavController,
        private readonly platform: Platform,
        private readonly loadingCtrl: LoadingController,
        private readonly toastCtrl: ToastController,
        private readonly authService: AuthService,
        private readonly translateService: TranslateService,
        private readonly onboardingService: OnboardingService,
        private readonly pushNotificationsService: PushNotificationsService,
    ) {
    }

    ngOnInit() {
        this.authService.authStatus.subscribe({
            next: status => {
                if (status.authDone && status.authData && !this.authData) {
                    this.authData = status.authData;
                    this.displayNotificationButtons = this.platform.is('capacitor');
                }
            }
        });
    }

    slideForward(){
        this.swiper.slideNext();
    }

    slideBack() {
        this.swiper.slidePrev();
    }

    close(){
        // If we need to select a course, go to the course selection page
        if (this.authData.data.learningPeriod.school_path.enable_select_skill_path) {
            this.onboardingService.goTo('pick-course', true);
            return;
        }

        // If we need to select skills, go to the skill selection page
        this.onboardingService.skillOrSkip();
    }

    async checkNotifications(){
        await this.pushNotificationsService.register();
        return this.close();
    }

    canSlideBack() {
        return this.swiper.activeIndex > 0;
    }

    canSlideForward() {
        return this.swiper.activeIndex < this.swiper.slides.length - 1;
    }

    authorize() {
        if (this.platform.is('capacitor')) {
            // Check permission
            this.checkNotifications();
        } else {
            // Go to finished step
            this.close();
        }
    }
}
