<ng-container *ngIf="items.length > 0 else emptyContainer">
  <div class="d-flex p-1 gap-05 justify-content-start flex-wrap">
    <button
            class="btn btn-rounded p-1 fs-5"
            [class.btn-green]="!disabled"
            [class.btn-grey]="disabled"
            [class.btn-outlined]="!selectedItems.includes(item.value)"
            *ngFor="let item of items"
            (click)="toggleSelection(item.value)"
            type="button"
    >
      {{ item.label }}
    </button>
  </div>
</ng-container>
<ng-template #emptyContainer>
  <p class="m-0 p-2 text-muted text-center">
    {{ 'LISTE_VIDE' | translate }}
  </p>
</ng-template>
