import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import { App } from '@capacitor/app';

@Injectable({
    providedIn: 'root'
})
export class DeepLinkingService {

    constructor(
        private readonly navCtrl: NavController
    ) {
    }

    registerDeepLinking() {
        App.addListener('appUrlOpen', (data: any) => {
            this.handleDeepLinkingUrl(data.url);
        });
    }

    public async handleDeepLinking() {
        const launchUrl = await App.getLaunchUrl();
        if (launchUrl?.url) {
            this.handleDeepLinkingUrl(launchUrl.url);
        }
    }

    private handleDeepLinkingUrl(url: string) {
        this.navCtrl.navigateForward(url);
    }

}
