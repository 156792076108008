<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex justify-content-start align-items-center gap-1 text-muted">
    <ng-container *ngFor="let star of [1,2,3,4,5]">
      <ng-container *ngIf="rating < star">
        <ion-icon name="star-outline" [style.color]="getBackgroundColor(star)" (click)="rate(star)" class="clickable fs-3"></ion-icon>
      </ng-container>
      <ng-container *ngIf="rating >= star">
        <ion-icon name="star" [style.color]="getBackgroundColor(star)" (click)="rate(star)" class="clickable fs-3"></ion-icon>
      </ng-container>
    </ng-container>
    <ion-icon *ngIf="noOpinionAvailable" class="clickable fs-2" [class.text-green]="isDefined() && rating === -1" name="close-outline" (click)="rate(-1)"></ion-icon>
  </div>
  <p *ngIf="isDefined()">
    {{ getLabel(rating)|translate }}
  </p>
</div>
